import { FEATURE_NAMES, IReleaseFeatures } from "./types";

interface ReleaseFeaturesProps {
  isProductionEnv: boolean;
  isDevelopmentEnv: boolean;
}

export const releaseFeatures: ({
  isProductionEnv,
  isDevelopmentEnv,
}: ReleaseFeaturesProps) => IReleaseFeatures[] = ({
  isProductionEnv,
  isDevelopmentEnv,
}: ReleaseFeaturesProps) => {
  return [
    {
      name: FEATURE_NAMES.ChangeLanguage,
      enabled: false,
    },
    {
      name: FEATURE_NAMES.ChangePassword,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.ShowSecuritySettings,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.CcaFileDownload,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.ShowNotifications,
      enabled: false,
    },
    {
      name: FEATURE_NAMES.ShowInviteUser,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.ShowTutorials,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.ShowToggle2FA,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.ShowDriversLastActivityFilter,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.ShowHistoryButton,
      enabled: false,
    },
    {
      name: FEATURE_NAMES.ShowCompanySelector,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.ShowFindTool,
      enabled: false, // Change to true when changes are made in BE
    },
    {
      name: FEATURE_NAMES.AllowedToCreateNewClientCompany,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.AllowedToChangeRootCompany,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.AllowedToInviteAllRoles,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.AllowedFullControlOfUsers,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.CancelInvitation,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.ResendInvitation,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.ShowDeleteCompanyButton,
      enabled: true,
    },
    {
      name: FEATURE_NAMES.ShowAdditionalNavigationButtons,
      enabled: false,
    },
  ];
};
