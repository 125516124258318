import { useRecoilState, useRecoilValue } from "recoil";

import useRefreshToken from "./useRefreshToken";

import { cancelSource, isCanceled, axiosPrivate } from "../axios";

import { setTokensToLocalStorage } from "../../shared/functions/auth";
import { networkError, serverError, tokensAtom } from "../../atoms/atoms";

const useAxiosPrivate = () => {
  const refreshToken = useRefreshToken();
  const tokenInfo = useRecoilValue(tokensAtom);
  const [, setIsServerError] = useRecoilState(serverError);
  const [, setIsNetworkError] = useRecoilState(networkError);

  axiosPrivate.interceptors.request.use(
    async (config: any) => {
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = ` bearer ${tokenInfo.token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  let refreshingFunc: any = undefined;

  axiosPrivate.interceptors.response.use(
    (response) => {
      return response;
    },

    async function(error: any) {
      const originalRequest = error?.config;
      if (
        // removed check for 400 status code, since it impacts unexpected behaviour of refreshToken api call infinit loop.
        error?.response?.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        try {
          // `refreshingFunc` is global, e.g. 2 expired requests will get the same function pointer and await same function.
          if (!refreshingFunc) {
            refreshingFunc = refreshToken();
          }

          const resp = await refreshingFunc;
          if (resp) {
            setIsServerError(undefined);
            const access_token = resp?.token;
            setTokensToLocalStorage(resp);
            originalRequest.headers["Authorization"] = `Bearer ${access_token}`;

            // retry original request
            return axiosPrivate(originalRequest);
          }
        } catch (err) {
          return Promise.reject(err);
        } finally {
          refreshingFunc = undefined;
        }
      }

      if (error?.response?.status === 500) {
        setIsServerError(error);
      }
      if (error?.message === "Network Error") {
        setIsNetworkError(error);
      }

      // if we don't have token in local storage or error is not 401 or 400, just return error and break req.
      return Promise.reject(error);
    }
  );

  const getData = (resource: string, headers?: any) =>
    axiosPrivate.get(resource, headers);

  const postData = (resource: string, payload?: any) =>
    axiosPrivate.post(resource, payload);

  const putData = (resource: string, payload?: any) =>
    axiosPrivate.put(resource, payload);

  const patchData = (resource: string) => axiosPrivate.patch(resource);

  const deleteData = (resource: string, id?: number | string) => {
    const deleteString = id ? `${resource}/${id}` : resource;

    return axiosPrivate.delete(deleteString);
  };

  const bulkDeleteData = (resource: string, payload: any) =>
    axiosPrivate.post(`${resource}/BulkDelete`, payload);

  const bulkRemoveData = (resource: string, payload: any) =>
    axiosPrivate.post(`${resource}/Bulk/Remove`, payload);

  return {
    axiosPrivate,
    getData,
    postData,
    putData,
    deleteData,
    bulkDeleteData,
    bulkRemoveData,
    cancelSource,
    isCanceled,
    patchData,
  };
};

export default useAxiosPrivate;
