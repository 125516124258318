/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Paper } from "@mui/material";
import { ReactNode, useContext } from "react";
import { useRecoilValue } from "recoil";

import AccordionDetailsCardTitleArea from "./components/AcordionDetailsCardTitleArea";

import { useStyles } from "../../../../styles";

import useTutorialMode from "../../../../../TutorialMode/hooks/useTutorialMode";

import {
  tableDetailsLoading,
  tableLoading,
} from "../../../../../../atoms/atoms";
import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import { useStyles as sharedStyles } from "../../../../../../shared";

export interface AccordionDetailsCardProps {
  cardTitle?: string;
  children: ReactNode;
  maxCount?: number;
  currentCount?: number;
  quickTitleAction?: ReactNode;
  titleInlineIcon?: ReactNode;
  showTitleArea?: boolean;
  customStyle?: {};
  testid?: string;
}

const AccordionDetailsCard = ({
  cardTitle,
  maxCount,
  currentCount,
  quickTitleAction,
  children,
  titleInlineIcon,
  showTitleArea = true,
  customStyle,
  testid,
}: AccordionDetailsCardProps) => {
  const { colors } = useContext(ThemeContext);

  const { highlightElementLikeSpotlight } = useTutorialMode();

  const isDetailsLoading = useRecoilValue(tableDetailsLoading);
  const isTableLoading = useRecoilValue(tableLoading);

  const { firstCollapsablePaperCard } = useStyles();
  const { tutorialHighlight } = sharedStyles();

  const displayLoader = isDetailsLoading || isTableLoading;

  const highlightElement = highlightElementLikeSpotlight({
    accordionDetails: true,
  });

  return (
    <Paper
      data-testid={testid || "acordion-details-card"}
      id="acordion-details-card"
      css={css([
        firstCollapsablePaperCard,
        {
          border: `1px solid ${colors.gray200}`,
          margin: "24px 16px 24px 24px",
        },
        customStyle,
        highlightElement && tutorialHighlight, // this is a workaround of tutorial spotlight in apply schedule modal, since the original spotlight of tutorial does not work as it should
      ])}
    >
      {showTitleArea && (
        <AccordionDetailsCardTitleArea
          title={cardTitle || ""}
          displayLoader={displayLoader}
          colors={colors}
          titleInlineAddition={
            maxCount ? `(${currentCount}/${maxCount})` : undefined
          }
          titleInlineIcon={titleInlineIcon}
          quickAction={quickTitleAction}
        />
      )}
      {children}
    </Paper>
  );
};

export default AccordionDetailsCard;
