import { useTranslation } from "react-i18next";

import VehicleScheduleDetailsSingleCard from "./VehicleScheduleDetailsSingleCard/VehicleScheduleDetailsSingleCard";
import DriverScheduleDetailsSingleCard from "./DriverScheduleDetailsSingleCard/DriverScheduleDetailsSingleCard";

import ScheduleQuickDownload from "../ScheduleQuickDownload/ScheduleQuickDownload";
import AccordionDetailsCard from "../AccordionDetailsCard/AccordionDetailsCard";
import NoCards from "../NoCards/NoCards";

import {
  DetailsDto,
  DETAILS_TITLES,
  ScheduleDto,
  DetailsDataType,
} from "../../../../types";

import { BANNER_MESSAGE_STATUSES } from "../../../../../BannerMessage/types";

import {
  DRIVERS_ACTIONS,
  ROUTES,
  VEHICLES_ACTIONS,
} from "../../../../../../shared";
import { SCHEDULES_LIMITS } from "../../../../../../Pages/Vehicles/types";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { USER_PERMISSIONS } from "../../../../../../roles/types/enums";

export interface ResourceScheduleCardsProps {
  detailsData: DetailsDataType;
  pageLocation: ROUTES;
  isLoading: boolean;
}

const ResourceScheduleCards = ({
  detailsData,
  pageLocation,
  isLoading,
}: ResourceScheduleCardsProps) => {
  const { getAccessStatus } = useAuth();

  const { t } = useTranslation();

  const hasQuickDownload = detailsData?.schedules?.some(
    (schedule) => schedule.quick
  );

  const renderListOfSchedules = (detailsData: DetailsDto) => {
    const schedulesFromDetails = detailsData?.schedules;

    const scheduleData: { [key: string]: any } = {
      [ROUTES.Vehicles]: {
        noCardsText: "no vehicle schedule",
        noCardsTestId: "vehicle-details-no-schedule",
        resourceAction: VEHICLES_ACTIONS.QuickScheduleDownload,
        singleCardComponent: VehicleScheduleDetailsSingleCard,
      },
      [ROUTES.Drivers]: {
        noCardsText: "no driver schedule",
        noCardsTestId: "driver-details-no-schedule",
        resourceAction: DRIVERS_ACTIONS.QuickScheduleDownload,
        singleCardComponent: DriverScheduleDetailsSingleCard,
      },
    };

    const {
      noCardsText,
      noCardsTestId,
      resourceAction,
      singleCardComponent: SingleCardComponent,
    } = scheduleData[pageLocation];

    const canManageSchedules = getAccessStatus(
      USER_PERMISSIONS.ManageSchedules
    );

    const renderQuickDownload = () =>
      canManageSchedules && (
        <ScheduleQuickDownload
          resourceAction={resourceAction}
          detailsData={detailsData}
          hasQuickDownload={hasQuickDownload}
        />
      );

    if (schedulesFromDetails?.length === 0) {
      return (
        <NoCards
          detailsData={detailsData}
          status={BANNER_MESSAGE_STATUSES.Warning}
          messageText={noCardsText}
          testId={noCardsTestId}
          quickAction={renderQuickDownload()}
        />
      );
    }

    return (
      <AccordionDetailsCard
        cardTitle={t(`Details##${DETAILS_TITLES.Schedules}`)}
        maxCount={SCHEDULES_LIMITS.MaxScheduleCount}
        currentCount={detailsData.schedulesCount}
        quickTitleAction={renderQuickDownload()}
      >
        {schedulesFromDetails?.map((schedule: ScheduleDto, index: number) => {
          return (
            <SingleCardComponent
              key={schedule.scheduleId}
              schedule={schedule}
              index={index}
              totalCount={schedulesFromDetails.length}
              canManageSchedules={canManageSchedules}
            />
          );
        })}
      </AccordionDetailsCard>
    );
  };

  return isLoading ? (
    <></>
  ) : (
    <>{detailsData && renderListOfSchedules(detailsData)}</>
  );
};

export default ResourceScheduleCards;
