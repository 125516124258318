import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import SchedulesMainDialogContent from "./SchedulesMainDialogContent/SchedulesMainDialogContent";

import useSchedulesMainDialog from "../../../hooks/useSchedulesMainDialog";

import Dialog from "../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import { pageGlobalStateObjectResult } from "../../../../../Table/functions";
import ConfirmCloseDialog from "../../../../../Schedules/components/ConfirmCloseDialog/ConfirmCloseDialog";
import { ResourceParams } from "../../../../../Schedules/types";
import useTutorialMode from "../../../../../TutorialMode/hooks/useTutorialMode";

import {
  GeneralDialogProps,
  TABLE_NAMES,
  TableRowData,
  useMediaQueries,
} from "../../../../../../shared";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import {
  filterQueryParams,
  selectedTableRows,
  selectedVehicleSchedule,
  tablePageData,
} from "../../../../../../atoms/atoms";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { USER_PERMISSIONS } from "../../../../../../roles/types/enums";

interface SchedulesMainDialogProps {
  resourceParams: ResourceParams;
}

const SchedulesMainDialog = ({
  isOpen,
  resourceParams,
  onClose,
}: GeneralDialogProps & SchedulesMainDialogProps) => {
  const { getAccessStatus } = useAuth();
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();
  const { handleCommonError, handleResponse } = useResponse();
  const { putData } = useAxiosPrivate();
  const { isTutorialOpen, highlightElementLikeSpotlight } = useTutorialMode();

  const location = useLocation();

  const filterParams = useRecoilValue(filterQueryParams);
  const pageData = useRecoilValue(tablePageData);
  const selectedRows = useRecoilValue(selectedTableRows);

  const [selectedSchedule, setSelectedSchedule] = useRecoilState(
    selectedVehicleSchedule
  );

  const [isLoading, setIsLoading] = useState(false);
  const [triSourceTabValue, setTriSourceTabValue] = useState(0);
  const [disableFileteredTab, setDisableFileteredTab] = useState<boolean>(
    false
  );
  const [disableSelectedTab, setDisableSelectedTab] = useState<boolean>(false);
  const [filteredItems, setFilteredItems] = useState<string[]>([]);

  const resourceCount = selectedRows?.length;

  const isReady =
    (selectedSchedule && resourceCount > 0) ||
    (selectedSchedule && triSourceTabValue === 2) ||
    (selectedSchedule && triSourceTabValue === 1 && resourceCount === 0);

  const { actionMain, resourceName } = resourceParams;

  const highlightElement = highlightElementLikeSpotlight({
    applyButton: true,
  });

  const {
    handleCloseSchedulesMainDialog,
    isCloseDialogConfirmationOpen,
    handleConfirmCloseDialog,
    handleSuccess,
  } = useSchedulesMainDialog({
    resourceCount,
    isTutorialOpen,
    onClose,
    actionMain,
    resourceParams,
    handleResponse,
  });

  useEffect(() => {
    const filteredItemsIds = pageData?.items?.map(
      (rowItem: TableRowData) => rowItem.id
    );
    setFilteredItems(filteredItemsIds as string[]);
  }, [pageData]);

  useEffect(() => {
    const filteredValues = pageGlobalStateObjectResult(
      location.pathname,
      filterParams
    );

    const filtersFlatMap =
      filteredValues &&
      Object.values(filteredValues).flatMap((value: any) => [...value]);

    const showSelected =
      (selectedRows?.length > 0 && !filtersFlatMap) ||
      (selectedRows?.length > 0 && filtersFlatMap.length === 0) ||
      (selectedRows?.length > 0 && filtersFlatMap.length > 0);

    const showFiltered =
      selectedRows?.length === 0 && filtersFlatMap?.length > 0;

    let initialTriSourceTabValue: number;

    if (showSelected) {
      initialTriSourceTabValue = 0;
    } else if (showFiltered) {
      initialTriSourceTabValue = 1;
    } else {
      initialTriSourceTabValue = 2;
    }

    setDisableFileteredTab((!showSelected && !showFiltered) || showSelected);
    setDisableSelectedTab((!showSelected && !showFiltered) || showFiltered);

    setTriSourceTabValue(initialTriSourceTabValue);
  }, [selectedRows, filterParams, location.pathname]);

  const handleSubmit = async () => {
    if (isReady) {
      const isFilteredTabActive =
        triSourceTabValue === 1 && resourceCount === 0;

      const applyToAll = triSourceTabValue === 2;

      try {
        setIsLoading(true);
        const isVehicleSchedules = resourceName === TABLE_NAMES.Vehicles;

        const itemsArray = isFilteredTabActive ? filteredItems : selectedRows;

        const payload = {
          scheduleId: selectedSchedule.id,
          ...(isVehicleSchedules
            ? { vehicleIds: itemsArray }
            : { driverIds: itemsArray }),
        };

        const applyToAllPayload = {
          scheduleId: selectedSchedule.id,
        };

        if (applyToAll) {
          await putData(
            resourceParams.apiResourceApplyToAll,
            applyToAllPayload
          );
        } else {
          await putData(resourceParams.apiResourceApply, payload);
        }

        handleSuccess(
          selectedRows,
          isVehicleSchedules,
          triSourceTabValue === 2,
          isFilteredTabActive ? filteredItems : undefined, // filteredItems are the items that are shown in the table after filtering
          triSourceTabValue === 2 ? filteredItems : undefined // allItems here are all the items that are shown in the table - same values as filteredItems if no filter is applied
        );
      } catch (error) {
        handleCommonError(error, t);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const deselectSchedule = () => {
    setSelectedSchedule(null);
  };

  const canManageSchedules = getAccessStatus(USER_PERMISSIONS.ManageSchedules);

  const descriptionType = canManageSchedules
    ? "description"
    : "description read-only";

  return (
    <>
      <Dialog
        title={t(`Schedules##${actionMain}##title`)}
        description={t(`Schedules##${actionMain}##${descriptionType}`)}
        extendToMaxWidth={toMd}
        isTitleSeparator
        isActionsSeparator
        disableEscapeKeyDown={isTutorialOpen}
        open={isOpen}
        close={handleCloseSchedulesMainDialog}
        submit={handleSubmit}
        width={!toMd ? DialogWidth.Dialog920px : undefined}
        TransitionProps={{
          onExited: deselectSchedule,
        }}
        actions={
          <DialogActions
            onClose={handleCloseSchedulesMainDialog}
            isLoading={isLoading}
            isreadyToConfirm={isReady}
            hideAcceptButton={!canManageSchedules}
            confirmationButtonText={t("Button##apply")}
            confirmationButtonTestId={`schedules-main-dialog-apply-button`}
            cancelButtonText={t("Button##close")}
            cancelButtonTestId={`schedules-main-dialog-close-button`}
            hasTooltip={!isReady}
            tooltipText={t("Schedules##apply button tooltip", {
              resourceName: resourceName?.slice(0, resourceName?.length - 1),
            })}
            // this is a workaround of tutorial spotlight in apply schedule modal, since the original spotlight of tutorial does not work as it should
            hasTutorialHighlight={highlightElement}
          />
        }
      >
        <SchedulesMainDialogContent
          resourceParams={resourceParams}
          resourceCount={resourceCount}
          canManageSchedules={canManageSchedules}
          deselectSchedule={deselectSchedule}
          tabValue={triSourceTabValue}
          setTabValue={setTriSourceTabValue}
          disableFileteredTab={disableFileteredTab}
          disableSelectedTab={disableSelectedTab}
        />
      </Dialog>
      <ConfirmCloseDialog
        resourceName={resourceName}
        isOpen={isCloseDialogConfirmationOpen}
        isLoading={isLoading}
        selectedSchedule={selectedSchedule}
        closeWithMainDialog={handleConfirmCloseDialog}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default SchedulesMainDialog;
