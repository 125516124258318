/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import { useStyles } from "../../styles/styles";
import { tableFooterStyle } from "../../styles/styleFunctions";
import { OptionPerPage } from "../../types";
import {
  pageGlobalStateObjectResult,
  getPageGlobalStateObjectValue,
} from "../../functions";

import Pagination from "../../../Pagination/Pagination";
import PaginationLoader from "../../../Loaders/PaginationLoader";
import Autocomplete from "../../../Autocomplete/Autocomplete";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import {
  getPageNumberFromLocalStorage,
  getRowsPerPageFromLocalStorage,
  useMediaQueries,
} from "../../../../shared";
import {
  currPage,
  tableLoading,
  tablePageData,
  tableRowsPerPage,
} from "../../../../atoms/atoms";
import RectangleLoader from "../../../../shared/components/loaders/RectangleLoader";
import useInitialLoading from "../../../../shared/hooks/useInitialLoading";

const TableFooter = () => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();
  const { fromLg, toLg } = useMediaQueries();
  const location = useLocation();
  const { isInitialLoading } = useInitialLoading();

  const pageData = useRecoilValue(tablePageData);
  const isLoading = useRecoilValue(tableLoading);

  const [pageToShow, setPageToShow] = useRecoilState(currPage);
  const [rowsPerPage, setRowsPerPage] = useRecoilState(tableRowsPerPage);

  const { tableFooter } = useStyles();

  const options: OptionPerPage[] = ["25", "50", "100"];

  const currentPageNumber = pageData ? pageData?.page : 0;
  const totalPageCount = pageData ? pageData?.totalCount : 0;

  const rowsCount = pageGlobalStateObjectResult(location.pathname, rowsPerPage);

  useEffect(() => {
    const pageToShowOnFirstRender =
      getPageNumberFromLocalStorage(location.pathname) === "0"
        ? "1"
        : getPageNumberFromLocalStorage(location.pathname);
    setPageToShow((prev: any) =>
      getPageGlobalStateObjectValue(
        location.pathname,
        prev,
        pageToShowOnFirstRender
      )
    );
    setRowsPerPage((prev: any) =>
      getPageGlobalStateObjectValue(
        location.pathname,
        prev,
        getRowsPerPageFromLocalStorage(location.pathname)
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, setPageToShow, setRowsPerPage]);

  const handlePageChange = (newPage: number) => {
    const pageValue = String(newPage);
    if (pageData && newPage > pageData.totalPages) {
      setPageToShow((prev: any) =>
        getPageGlobalStateObjectValue(
          location.pathname,
          prev,
          pageData.totalPages
        )
      );
    }
    setPageToShow((prev: any) =>
      getPageGlobalStateObjectValue(location.pathname, prev, pageValue)
    );
  };

  const handleRowsPerPageChange = (value: OptionPerPage) => {
    const currentPage = pageGlobalStateObjectResult(
      location.pathname,
      pageToShow
    );

    if (currentPage && pageData) {
      if (pageData.totalCount / +value < currentPage) {
        const maxPageCount = Math.ceil(pageData.totalCount / +value);
        setPageToShow((prev: any) =>
          getPageGlobalStateObjectValue(location.pathname, prev, maxPageCount)
        );
        setRowsPerPage((prev: any) =>
          getPageGlobalStateObjectValue(location.pathname, prev, value)
        );
      }
    }
    setRowsPerPage((prev: any) =>
      getPageGlobalStateObjectValue(location.pathname, prev, value)
    );
    // the change is stored to local storage through PageWrappers (with and without details),
    // by calling setPageParamsToLocalStorage()
  };

  const renderTableFooterContent = () => {
    if (isInitialLoading) {
      return (
        <>
          <RectangleLoader
            testId="table-footer-loader"
            customStyle={{
              backgroundColor: colors.gray200,
              margin: fromLg ? "0 24px" : "8px 0",
            }}
            width={toLg ? "calc(100% - 24px)" : "259px"}
            height="36px"
          />
          <PaginationLoader />
        </>
      );
    } else {
      return (
        <>
          <Autocomplete
            data-testid="rows-per-page-autocomplete"
            fullWidth={toLg}
            disableClearable
            size="small"
            labelLeft={t("Table##rows per page")}
            options={options}
            value={(rowsCount && String(rowsCount)) || "25"}
            onChange={(_, value) => handleRowsPerPageChange(value)}
            customStyle={{
              flexDirection: "row",
              gap: "8px",
              width: toLg ? "calc(100% - 24px)" : "unset",
            }}
            labelCustomStyle={{
              width: toLg ? "100px" : "unset",
            }}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            disabled={pageData?.totalCount === 0}
          />

          <Pagination
            page={currentPageNumber}
            total={totalPageCount}
            perPage={+rowsCount}
            onChange={handlePageChange}
            isInitialLoading={isLoading && !pageData}
          />
        </>
      );
    }
  };

  return (
    <div
      data-testid="table-footer"
      css={css([tableFooterStyle(toLg, fromLg, colors) as {}, tableFooter])}
    >
      {renderTableFooterContent()}
    </div>
  );
};

export default TableFooter;
