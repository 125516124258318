/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { Typography } from "@mui/material";

interface GoBackProps {
  onGoBack: () => void;
  text: string;
}

const GoBack = ({ onGoBack, text }: GoBackProps) => {
  return (
    <div
      data-testid="go-back"
      css={css({
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      })}
      onClick={onGoBack}
    >
      <ChevronLeftRoundedIcon
        sx={{ width: "20px", height: "20px" }}
        data-testid="ChevronLeftRoundedIcon"
      />
      <Typography variant="body2">{text}</Typography>
    </div>
  );
};

export default GoBack;
