import FindToolContent from "../FindToolContent/FindToolContent";

import DialogCustom from "../../../../../DialogCustom/DialogCustom";

interface MobileFindToolProps {
  isOpen: boolean;
  onClose: () => void;
}

const MobileFindTool = ({ isOpen, onClose }: MobileFindToolProps) => {
  return (
    <DialogCustom
      closeAfterTransition={false} // prevents from aria-hidden warning on closing dialog first time after render
      noPadding
      open={isOpen}
      close={onClose}
      data-testid="mobile-find-tool-dialog"
    >
      <FindToolContent close={onClose} />
    </DialogCustom>
  );
};

export default MobileFindTool;
