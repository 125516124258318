import { ReactElement } from "react";

import Tooltip from "./Tooltip";

import { TooltipPlacementName } from "../Pagination/types/types";

interface OptionalTooltipProps {
  children: ReactElement<any, any>;
  isDisabled: boolean;
  tooltipTitle: string;
  tooltipPlacement: TooltipPlacementName;
}

const OptionalTooltip = ({
  children,
  isDisabled,
  tooltipTitle,
  tooltipPlacement,
}: OptionalTooltipProps) => {
  if (!isDisabled) {
    return (
      <Tooltip
        title={tooltipTitle}
        size="medium"
        placement={tooltipPlacement}
        customWordBreak="break-all"
      >
        {children}
      </Tooltip>
    );
  }
  return children;
};

export default OptionalTooltip;
