import { AxiosError } from "axios";
import { useCallback, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";

import useAxiosPrivate from "../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../api/resources";
import { ALERT_STATUS } from "../../../../../context/alert/types";
import useResponse from "../../../../../shared/hooks/useResponse";
import {
  companyListForCompanySelector,
  rootCompanyListForCompanySelector,
} from "../../../../../atoms/atoms";

const useCompanies = (
  removeExtraApiCall?: boolean,
  noInitialLoad?: boolean,
  fromCompanySelector?: boolean // this is used to avoid redundant api call on checkbox first change
) => {
  const { handleResponse } = useResponse();
  const { getData } = useAxiosPrivate();

  const [companyList, setCompanyList] = useRecoilState(
    companyListForCompanySelector
  );

  const [rootCompanyList, setRootCompanyList] = useRecoilState(
    rootCompanyListForCompanySelector
  );

  const initialized = useRef(false);
  const resource = ApiResources.CompanyList;

  const getQueryData = useCallback(
    async (callRefreshCompanyList?: boolean, rootCompanyId?: string) => {
      // this check is created to avoid double api call in companies page on page refresh.
      if (
        !removeExtraApiCall ||
        (removeExtraApiCall && callRefreshCompanyList)
      ) {
        try {
          const isRootNeeded = rootCompanyId ? `/${rootCompanyId}` : "";

          const response = await getData(`${resource}${isRootNeeded}`);

          !rootCompanyId && setRootCompanyList(response.data.rootCompanies);
          setCompanyList(response.data.companies);
        } catch (error) {
          const err = error as AxiosError;
          if (err) {
            handleResponse(ALERT_STATUS.Critical, err.message);
          }
        } finally {
          initialized.current = true;
        }
      }
    },
    [
      getData,
      handleResponse,
      resource,
      setCompanyList,
      setRootCompanyList,
      removeExtraApiCall,
    ]
  );

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      !noInitialLoad && fromCompanySelector && getQueryData();
    }
  }, [
    getData,
    getQueryData,
    handleResponse,
    resource,
    noInitialLoad,
    removeExtraApiCall,
    fromCompanySelector,
  ]);

  return { companyList, rootCompanyList, getQueryData };
};

export default useCompanies;
