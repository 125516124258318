/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import SchedulesSourceTabs from "../SchedulesSourceTabs/SchedulesSourceTabs";
import ItemsInfoBlock from "../ItemsInfoBlock/ItemsInfoBlock";

import useSchedulesMainDialogContent from "../../../../hooks/useSchedulesMainDialogContent";

import BannerMessage from "../../../../../../BannerMessage/BannerMessage";
import { BANNER_MESSAGE_STATUSES } from "../../../../../../BannerMessage/types";
import {
  MAX_SCHEDULES_COUNT,
  ResourceParams,
  ScheduleDataDto,
} from "../../../../../../Schedules/types";
import ScheduleCards from "../../../../../../Schedules/components/ScheduleCards/ScheduleCards";
import useTutorialMode from "../../../../../../TutorialMode/hooks/useTutorialMode";

import { tablePageData } from "../../../../../../../atoms/atoms";
import useAxiosPrivate from "../../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../../shared/hooks/useResponse";
import useFilteredValues from "../../../../../../Filters/hooks/useFilteredValues";

interface SchedulesMainDialogContentProps {
  resourceParams: ResourceParams;
  resourceCount: number;
  canManageSchedules: boolean;
  deselectSchedule: () => void;
  tabValue: number;
  setTabValue: Dispatch<SetStateAction<number>>;
  disableFileteredTab: boolean;
  disableSelectedTab: boolean;
}

const SchedulesMainDialogContent = ({
  resourceParams,
  resourceCount,
  canManageSchedules,
  deselectSchedule,
  tabValue,
  setTabValue,
  disableFileteredTab,
  disableSelectedTab,
}: SchedulesMainDialogContentProps) => {
  const { t } = useTranslation();
  const {
    isTutorialActive,
    stepIndex,
    stepGroup,
    callTutorialWithTimeout,
  } = useTutorialMode();
  const { getData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();
  const { filteredValues } = useFilteredValues();

  const pageData = useRecoilValue(tablePageData);

  const [isLoading, setIsLoading] = useState(true);
  const [schedulesData, setSchedulesData] = useState<ScheduleDataDto[] | null>(
    null
  );
  const [globalScheduleExists, setGlobalScheduleExists] = useState(true);

  const initialized = useRef(false);

  const { apiResource, resourceName } = resourceParams;

  const isSelectedTab = tabValue === 0;
  const isFilteredTab = tabValue === 1;
  const isAllTab = tabValue === 2;
  const displayFilters = filteredValues.length > 0 && isFilteredTab;

  const {
    generateBadgeText,
    generateItemsCountToDisplay,
    generateSelectedTabDescription,
  } = useSchedulesMainDialogContent({
    isSelectedTab,
    isFilteredTab,
    isAllTab,
    resourceName,
    pageData,
    resourceCount,
    t,
  });

  useEffect(() => {
    retrieveDataIfNotInitialized();

    // eslint-disable-next-line
  }, [resourceCount]);

  const getSchedulesData = async () => {
    try {
      setIsLoading(true);

      const { data } = await getData(apiResource);

      const isGlobalTrue = data.some(
        (item: ScheduleDataDto) => item.global === true
      );

      deselectSchedule();
      setGlobalScheduleExists(isGlobalTrue);
      setSchedulesData(data);
      setIsLoading(false);

      if (isTutorialActive) {
        callTutorialWithTimeout(true, stepIndex, stepGroup, true, 200);
      }
    } catch (error) {
      handleCommonError(error, t);
    }
  };

  const retrieveDataIfNotInitialized = async () => {
    if (!initialized.current) {
      initialized.current = true;

      getSchedulesData();
    }
  };

  const scheduleCardLimitReached =
    (schedulesData &&
      (schedulesData.length >= MAX_SCHEDULES_COUNT.GlobalConfigured ||
        (!globalScheduleExists &&
          schedulesData?.length >= MAX_SCHEDULES_COUNT.NoGlobalConfigured))) ??
    false;

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    deselectSchedule();
  };

  return (
    <>
      {canManageSchedules && (
        <>
          <SchedulesSourceTabs
            tabValue={tabValue}
            onTabChange={handleTabChange}
            t={t}
            disableSelectedTab={disableSelectedTab}
            disableFileteredTab={disableFileteredTab}
            resourceName={resourceName}
          />

          {scheduleCardLimitReached && !isLoading && (
            <BannerMessage
              data-testid="max-number-reached-schedules-banner"
              status={BANNER_MESSAGE_STATUSES.Info}
              title={t("Schedules##max number reached")}
              css={css({ marginBottom: "16px" })}
            />
          )}
          <ItemsInfoBlock
            resourceName={resourceName}
            isAllTab={isAllTab}
            generateSelectedTabDescription={generateSelectedTabDescription}
            generateBadgeText={generateBadgeText}
            generateItemsCountToDisplay={generateItemsCountToDisplay}
            displayFilters={displayFilters}
            t={t}
            filteredValues={filteredValues}
          />
        </>
      )}
      <ScheduleCards
        schedulesData={schedulesData}
        isScheduleDataLoading={isLoading}
        globalScheduleExists={globalScheduleExists}
        limitReached={scheduleCardLimitReached}
        resourceParams={resourceParams}
        canManageSchedules={canManageSchedules}
        getSchedulesData={getSchedulesData}
      />
    </>
  );
};

export default SchedulesMainDialogContent;
