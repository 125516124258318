/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import FindToolContent from "../FindToolContent/FindToolContent";

import Menu from "../../../../../Menu/Menu";

import { MenuAnchorEl } from "../../../../../../shared";

interface IDesktopFindToolProps {
  onClose: () => void;
  anchorEl: MenuAnchorEl;
}

const DesktopFindTool = ({ onClose, anchorEl }: IDesktopFindToolProps) => {
  return (
    <Menu
      data-testid="desktop-find-tool-dialog"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      css={css({
        "& .MuiPaper-root": {
          width: "566px",
        },
      })}
    >
      <FindToolContent close={onClose} />
    </Menu>
  );
};

export default DesktopFindTool;
