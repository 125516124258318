import { Dispatch, SetStateAction, useContext, useState } from "react";

import ScheduleDetailsCardContent from "../ScheduleDetailsCardContent/ScheduleDetailsCardContent";
import SingleDetailsAcordionItem from "../../AccordionDetailsCard/components/SingleDetailsAcordionItem";
import RemoveScheduleButton from "../../ScheduleActions/RemoveSchedule/RemoveScheduleButton";
import ScheduleHistoryButton from "../../ScheduleActions/ScheduleHistoryButton";
import ScheduleDetailsButton from "../../ScheduleActions/ScheduleDetails/ScheduleDetailsButton";

import { DriverScheduleDto } from "../../../../../types";

import { MenuAnchorEl, TABLE_NAMES } from "../../../../../../../shared";
import { ThemeContext } from "../../../../../../../context/theme/ThemeContextProvider";
import { useReleaseFeatureToggle } from "../../../../../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../../../../../shared/featureToggles/types";

interface IDriverScheduleDetailsSingleCardProps {
  schedule: DriverScheduleDto;
  index: number;
  totalCount: number;
  canManageSchedules: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
}

const DriverScheduleDetailsSingleCard = ({
  schedule,
  index,
  totalCount,
  canManageSchedules,
  setIsLoading,
}: IDriverScheduleDetailsSingleCardProps) => {
  const { colors } = useContext(ThemeContext);

  const { isReleaseFeatureEnabled } = useReleaseFeatureToggle();

  const [isActionsOpen, setIsActionsOpen] = useState<MenuAnchorEl>(null);

  return (
    <SingleDetailsAcordionItem
      key={schedule.scheduleId}
      title={schedule.name}
      totalCount={totalCount}
      index={index}
      setIsLoading={setIsLoading}
      collapsable
      anchorEl={isActionsOpen}
      setIsActionsOpen={setIsActionsOpen}
      titleInlineActions={
        canManageSchedules && (
          <>
            {isReleaseFeatureEnabled(FEATURE_NAMES.ShowHistoryButton) && (
              <ScheduleHistoryButton schedule={schedule} colors={colors} />
            )}

            <ScheduleDetailsButton
              isDriversPage
              schedule={schedule}
              onActionsMenu={setIsActionsOpen}
            />
            <RemoveScheduleButton
              schedule={schedule}
              onActionsMenu={setIsActionsOpen}
            />
          </>
        )
      }
    >
      <ScheduleDetailsCardContent
        schedule={schedule}
        resourceName={TABLE_NAMES.Drivers}
        //lastDownloadAttempt and lastSuccessfulDownload are going to be shown only in quick schedule until the final solution in BE is cleared out. JIRA task: TACHO-2153

        lastDownloadAttempt={schedule?.quick}
        lastSuccessfulDownload={schedule?.quick}
        currentStatus={!schedule?.quick}
      />
    </SingleDetailsAcordionItem>
  );
};

export default DriverScheduleDetailsSingleCard;
