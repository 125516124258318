import { SetStateAction, useEffect, useState } from "react";

import SettingsContent from "./components/SettingsContent/SettingsContent";
import SettingsMenuDesktop from "./components/SettingsMenuDesktop";
import GeneralSettings from "./Dialogs/GeneralSettings/GeneralSettings";
import SecuritySettings from "./Dialogs/SecuritySettings/SecuritySettings";
import ApiManagement from "./Dialogs/ApiManagement/ApiManagement";
import CompanyCardAuth from "./Dialogs/CompanyCardAuth/CompanyCardAuth";

import { DIALOG_NAMES } from "../../types/enums";

import useTutorialMode from "../../../TutorialMode/hooks/useTutorialMode";
import { MenuAnchorEl, useMediaQueries } from "../../../../shared";
import SettingsButton from "../../../../shared/components/buttons/SettingsButton";

interface ISettingsProps {
  handleDrawer?: (value: boolean) => void;
}

const Settings = ({ handleDrawer }: ISettingsProps) => {
  const { fromMd } = useMediaQueries();

  const {
    isSettingsOpen,
    isTutorialActive,
    isTutorialOpen,
    stepGroup,
    stepIndex,
    callTutorialWithTimeout,
    handleTutorialExit,
    setIsSettingsOpen,
  } = useTutorialMode();

  const dialogOpenInitialState = {
    apiManagement: false,
    companyCardAuth: false,
    generalSettings: false,
    password: false,
    security: false,
  };

  const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(dialogOpenInitialState);

  useEffect(() => {
    isTutorialOpen && !isSettingsOpen && setAnchorEl(null);

    //eslint-disable-next-line
  }, [stepGroup, stepIndex, isTutorialOpen]);

  const openMenu = (e: { currentTarget: SetStateAction<MenuAnchorEl> }) => {
    setIsSettingsOpen(true);
    setAnchorEl(e.currentTarget);

    isTutorialActive &&
      callTutorialWithTimeout(true, stepIndex, stepGroup, true, 200);
  };

  const closeMenu = () => {
    isTutorialActive && handleTutorialExit();
    setAnchorEl(null);
    // close dialog if open
    closeDialog()
  };

  const openDialog = (name: DIALOG_NAMES) => {
    closeMenu();
    setIsDialogOpen({ ...isDialogOpen, [name]: true });
  };

  const closeDialog = () => {
    handleDrawer && handleDrawer(false);

    setIsDialogOpen(dialogOpenInitialState);
  };

  return (
    <>
      <SettingsButton openMenu={openMenu} />

      {fromMd ? (
        <SettingsMenuDesktop
          isMobile={false}
          anchorEl={anchorEl}
          close={closeMenu}
          openDialog={openDialog}
        />
      ) : (
        <SettingsContent
          isMobile={true}
          openDialog={openDialog}
          closeMenu={closeMenu}
        />
      )}

      {/* This is where settings dialogs go to */}

      <GeneralSettings
        isOpen={isDialogOpen.generalSettings}
        closeDialog={closeDialog}
      />
      <ApiManagement
        isOpen={isDialogOpen.apiManagement}
        closeDialog={closeDialog}
      />
      <SecuritySettings
        onCloseDialog={closeDialog}
        isOpen={isDialogOpen.security}
      />
      <CompanyCardAuth
        isOpen={isDialogOpen.companyCardAuth}
        closeDialog={closeDialog}
      />
    </>
  );
};

export default Settings;
