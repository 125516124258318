/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { useContext } from "react";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import { ThemeColors } from "../../shared";

interface SeparatorProps {
  customStyle?: CSSObject;
  color?: ThemeColors[keyof ThemeColors];
  [key: string]: any;
}

const Separator = ({ customStyle, color, light, ...props }: SeparatorProps) => {
  const { colors } = useContext(ThemeContext);

  return (
    <div
      {...props}
      data-testid="separator"
      css={css({
        height: "1px",
        background: color || colors.gray300,
        ...customStyle,
      })}
    />
  );
};

export default Separator;
