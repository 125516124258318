// Text for the WelcomeDialog component will not be translated as it is only for the BETA version of the application.
export const betaVersionParagraphs = [
  {
    title: null,
    description:
      "We’re excited to have you here! As part of our BETA testing phase, you’re getting early access to try out our product and help shape its future. Here’s what you need to know:",
    beforeList: null,
    list: null,
    afterParagraph: null,
  },
  {
    title: "What is BETA Testing?",
    description:
      "BETA testing is an early release version of the product that allows us to gather valuable feedback from users like you.",
    beforeList: null,
    list: null,
    afterParagraph: null,
  },
  {
    title: null,
    description:
      "Certain features may be incomplete, missing, or not fully functional during this phase. Bugs are also expected as we work to improve the system.",
    beforeList: "Important Info:",
    list: [
      {
        bold: "Your Feedback Matters —",
        text:
          'Your input will help shape the final product. Provide feedback <a href="https://forms.office.com/e/BSBQQYRHXj" target="blank" class="link">here</a>.',
      },
      {
        bold: "Expect Some Glitches —",
        text:
          "Things may not always work perfectly, as this is a BETA version. If you encounter any issues, please reach out to our support team.",
      },
      {
        bold: "Data Purge —",
        text:
          "Please note that all data collected during BETA phase will be deleted before the official release of TachoSync.",
      },
      {
        bold: "Tutorials Available —",
        text:
          "Tutorials to help you set up the system for successful data downloads are available in the <strong>Settings</strong>. These guides cover essential steps to get you up and running smoothly.",
      },
    ],
    afterParagraph: null,
  },
  {
    title: null,
    description:
      "Thank you for being part of our journey to build a better product!",
    beforeList: null,
    list: null,
    afterParagraph: null,
  },
];

export const betaVersionCheckboxLabel =
  "<span>I understand </span>that all created data <span>will be deleted</span> at the end of Open BETA period.";
