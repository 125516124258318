import { TFunction } from "i18next";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import InputField from "../../../../../../InputField/InputField";
import { ClearInputIcon } from "../../../../../../InputField/components/ClearInputIcon";

interface SearchVehicleProps {
  t: TFunction<"translation", undefined>;
  searchValue: string;
  handleSearchValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearchValue: () => void;
  error: string | null;
}

const SearchVehicle = ({
  t,
  searchValue,
  handleSearchValue,
  clearSearchValue,
  error,
}: SearchVehicleProps) => {
  return (
    <InputField
      data-testid="search-device-input"
      autoFocus
      fullWidth
      size="medium"
      placeholder={t("Search##find tool##search vehicle IMEI")}
      iconLeft={<SearchRoundedIcon />}
      value={searchValue}
      onChange={handleSearchValue}
      iconRight={
        <ClearInputIcon value={searchValue} clearValue={clearSearchValue} />
      }
      errorText={error}
    />
  );
};

export default SearchVehicle;
