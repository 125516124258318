import { ReactNode, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

import "moment/locale/lt";

import LanguageContext, { LanguageInitialState } from "./LanguageContext";
import { useAuth } from "../Auth/AuthProvider";

import { DATE_FORMATS, LANGUAGES } from "../../shared";
import ukFlagSrc from "../../assets/flags/UnitedKingdom.png";
import ltFlagSrc from "../../assets/flags/Lithuania.png";
import {
  getStoredLanguage,
  getValidLanguage,
  setStoredLanguage,
} from "../../shared/functions/functions";

interface LanguageStateProps {
  children: ReactNode;
}

const LanguageProvider = ({ children }: LanguageStateProps) => {
  const { user } = useAuth();

  const { i18n } = useTranslation();

  const getLanguage = () => {
    return getValidLanguage(getStoredLanguage(), i18n.language as LANGUAGES);
  };

  const getFormatterData = () => {
    const formatter = new Intl.DateTimeFormat(undefined, {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });

    return formatter.formatToParts(new Date());
  };

  const getUserBrowserDateFormat = () => {
    const formattedParts = getFormatterData();

    return formattedParts
      .map((part) => {
        switch (part.type) {
          case "day":
            return DATE_FORMATS.Day;
          case "month":
            return DATE_FORMATS.Month;
          case "year":
            return DATE_FORMATS.Year;
          default:
            return "-";
        }
      })
      .join("");
  };

  const getDateFormat = () => {
    const dateFormatFromApi = user?.timeSettings?.dateFormat;

    if (!dateFormatFromApi) {
      return getUserBrowserDateFormat();
    }

    return dateFormatFromApi;
  };

  const state: LanguageInitialState = {
    languages: [
      {
        id: LANGUAGES.English,
        text: Object.keys(LANGUAGES)[
          Object.values(LANGUAGES).indexOf(LANGUAGES.English)
        ].toLowerCase(),
        flagSrc: ukFlagSrc,
        momentLocale: "en",
      },
      {
        id: LANGUAGES.Lithuanian,
        text: Object.keys(LANGUAGES)[
          Object.values(LANGUAGES).indexOf(LANGUAGES.Lithuanian)
        ].toLowerCase(),
        flagSrc: ltFlagSrc,
        momentLocale: "lt",
      },
    ],
    language: getLanguage(),
    userBrowserDateFormat: getUserBrowserDateFormat(),
    currentDateFormat: getDateFormat(),
  };

  useEffect(() => {
    setMomentLocale(state.language);
    // eslint-disable-next-line
  }, []);

  const setMomentLocale = (newLang: LANGUAGES) => {
    const currentLanguage = state.languages.find((lang) => lang.id === newLang);

    const localeLanguage = currentLanguage
      ? currentLanguage.momentLocale
      : "en";

    moment.locale(localeLanguage);
  };

  const setNewLanguage = async (lang: LANGUAGES) => {
    i18n.changeLanguage((lang as unknown) as string);
    setStoredLanguage((lang as unknown) as string);
    setMomentLocale(lang);

    // next: handle set new language by calling api
  };

  const languageContextValue = useMemo(
    () => ({
      languages: state.languages,
      language: state.language,
      userBrowserDateFormat: state.userBrowserDateFormat,
      currentDateFormat: state.currentDateFormat,
      setNewLanguage,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.language, state.languages]
  );

  return (
    <LanguageContext.Provider value={languageContextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
