import { Dispatch, SetStateAction, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";

import { DriversFileDataDto } from "../../../../../Pages/Files";
import {
  DRIVER_FILES_ACTIONS,
  RenderConditionOptions,
} from "../../../../../shared";
import { useFileDownload } from "../../../hooks";

const useDriversFilesInlineActions = () => {
  const { downloadFile } = useFileDownload();

  const [rowData, setRowData] = useState<DriversFileDataDto | null>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const openDialog = (data: any) => {
    setRowData(data);
    setOpen(true);
  };

  const closeDialog = () => {
    setRowData(null);
    setOpen(false);
  };

  const actionTitle = DRIVER_FILES_ACTIONS.Download;

  const inlineActions = [
    {
      title: actionTitle.charAt(0).toUpperCase() + actionTitle.slice(1),
      hasLoader: true,
      icon: <DownloadIcon />,
      clickAction: (
        data: DriversFileDataDto,
        setIsButtonDownload?: Dispatch<SetStateAction<boolean>> | undefined
      ) =>
        downloadFile({
          queryString: data.id,
          setIsLoading: setIsButtonDownload,
        }),
      // if renderConditions property is equal to true
      // then table will return inline action button
      renderConditions: ({ data, user }: RenderConditionOptions) => true,
    },
  ];

  return {
    inlineActions,
    rowData,
    isOpen,
    openDialog,
    closeDialog,
  };
};

export default useDriversFilesInlineActions;
