import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { deselectAllRows, pageGlobalStateObjectResult } from "../functions";

import useTableItemDetails from "../../DetailsSection/hooks/useTableItemdetails";
import { Actions } from "../../PageHeader/actions/types";
import useCompanies from "../../Navbar/components/CompanySelector/hooks/useCompanies";

import useAxiosPrivate from "../../../api/hooks/useAxiosPrivate";
import {
  selectedTableRows,
  tableData,
  tableDetails,
} from "../../../atoms/atoms";
import { ALERT_STATUS } from "../../../context/alert/types";
import {
  TableRowData,
  TABLE_NAMES,
  getValueInSearchField,
  RESOURCE_ACTIONS,
  convertStringBasedOnLength,
  ROUTES,
} from "../../../shared";
import useResponse from "../../../shared/hooks/useResponse";
import {
  capitalizeResourceFirstLetter,
  refreshTableAndUpdateDetails,
  removeSlashFromPath,
} from "../../../shared/functions/functions";
import useRefreshTableData from "../../../shared/hooks/useRefreshTableData";

const useActionsForSelection = () => {
  const { deleteData, bulkDeleteData, bulkRemoveData } = useAxiosPrivate();
  const { handleResponse, handleCommonError } = useResponse();
  const { refreshTableData } = useRefreshTableData();
  const { getTableItemDetails } = useTableItemDetails();
  const { t } = useTranslation();
  const location = useLocation();
  const { getQueryData } = useCompanies();

  const detailsSectionData = useRecoilValue(tableDetails);
  const tableRows = useRecoilValue<TableRowData[]>(tableData);
  const selectedRows = useRecoilValue<string[]>(selectedTableRows);

  const [, setSelectedRows] = useRecoilState<any>(selectedTableRows);

  const detailsData = pageGlobalStateObjectResult(
    location.pathname,
    detailsSectionData
  );

  const filterNotDeletingItems = () => {
    const filteredItems = tableRows.filter(
      (item) => !selectedRows.includes(item.id as string)
    );
    return filteredItems;
  };

  const getSuccessActionString = (
    actionString: string,
    isDeleteItems: boolean
  ) =>
    isDeleteItems
      ? actionString
      : convertStringBasedOnLength(selectedRows.length, actionString);

  const getResourceId = (
    deleteSelectedItems: boolean,
    detailsDataItemId: any
  ) => {
    const notSelectedRows = tableRows.filter(
      (row) => !selectedRows.includes(String(row.id))
    );

    const resourceIdForDelete =
      detailsData && notSelectedRows.length ? notSelectedRows[0].id : null;

    return deleteSelectedItems ? resourceIdForDelete : detailsDataItemId;
  };

  const refreshPageContent = (
    resourceString: string | number,
    payloadValues: string[] | string,
    action: RESOURCE_ACTIONS | string,
    deleteSelectedItems: boolean = true
  ) => {
    const successMessage = t(
      `Alert##successfully ${getSuccessActionString(
        action,
        deleteSelectedItems
      )}`,
      {
        resourceString,
      }
    );

    const valueInSearchField = getValueInSearchField(location);

    if (selectedRows.includes(detailsData?.id)) {
      if (filterNotDeletingItems().length > 0) {
        const detailsDataItemId = detailsData?.id;

        const itemHasOpenedDetails =
          detailsDataItemId &&
          (Array.isArray(payloadValues)
            ? payloadValues.includes(detailsDataItemId)
            : payloadValues === detailsDataItemId);

        const resourceId = getResourceId(
          deleteSelectedItems,
          detailsDataItemId
        );

        refreshTableAndUpdateDetails({
          valueInSearchField,
          resourceId,
          successMessage,
          itemHasOpenedDetails,
          getTableItemDetails,
          handleResponse,
          leaveRowsChecked: false,
        });
      } else {
        handleResponse(ALERT_STATUS.Success, successMessage);
        refreshTableData(true, valueInSearchField);
      }
    } else {
      handleResponse(ALERT_STATUS.Success, successMessage);
      refreshTableData(undefined, valueInSearchField);
      // deselect all table rows
      deselectAllRows(setSelectedRows);
    }
  };

  const deleteSingleItem = async (
    resource: TABLE_NAMES,
    setLoading: Dispatch<SetStateAction<boolean>>
  ) => {
    try {
      setLoading(true);
      await deleteData(resource, selectedRows[0]);

      const resourceString = capitalizeResourceFirstLetter(resource).slice(
        0,
        -1
      );

      refreshPageContent(
        resourceString,
        selectedRows[0],
        RESOURCE_ACTIONS.Deleted
      );
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        const errorMessage = err.message;
        handleResponse(ALERT_STATUS.Critical, errorMessage);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const generateBulkDeleteResourceString = (
    resource: TABLE_NAMES | ROUTES,
    companiesPage: boolean,
    numberOfItems?: number
  ) => {
    if (companiesPage) {
      if (numberOfItems === 1) {
        return t("General##company");
      } else {
        return `${numberOfItems} ${removeSlashFromPath(resource)}`;
      }
    } else {
      return capitalizeResourceFirstLetter(resource);
    }
  };

  const deleteBulkOfItems = async (
    resource: TABLE_NAMES | ROUTES,
    payload: object | null,
    setLoading: Dispatch<SetStateAction<boolean>>,
    numberOfItems?: number
  ) => {
    const companiesPage = resource === ROUTES.Companies;

    try {
      setLoading(true);
      await bulkDeleteData(resource, payload);

      const payloadValues = Object.values(payload!)[0];

      refreshPageContent(
        generateBulkDeleteResourceString(
          resource,
          companiesPage,
          numberOfItems
        ),
        payloadValues,
        RESOURCE_ACTIONS.Deleted
      );

      // refresh company list in company selector
      companiesPage && (await getQueryData(true));
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        handleCommonError(error, t);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const removeBulkOfItems = async (
    resource: TABLE_NAMES | string,
    payload: object | null,
    resourceAction: Actions,
    setLoading: Dispatch<SetStateAction<boolean>>,
    handleCloseDialog: () => void
  ) => {
    try {
      setLoading(true);
      await bulkRemoveData(resource, payload);

      const payloadValues = Object.values(payload!)[1];

      refreshPageContent(
        selectedRows.length,
        payloadValues,
        resourceAction,
        false
      );

       // deselect all table rows
       deselectAllRows(setSelectedRows);

      handleCloseDialog();
    } catch (error) {
      handleCommonError(error, t);
      setLoading(false);
    }
    setLoading(false);
  };

  return { deleteSingleItem, deleteBulkOfItems, removeBulkOfItems };
};

export default useActionsForSelection;
