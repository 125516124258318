import useMediaQuery from "@mui/material/useMediaQuery";

export const useMediaQueries = () => {
  const toSm = useMediaQuery("(max-width: 599px)", { noSsr: true });
  const fromSm = useMediaQuery("(min-width: 600px)", { noSsr: true });
  const toMd = useMediaQuery("(max-width: 839px)", { noSsr: true });
  const fromMd = useMediaQuery("(min-width: 840px)", { noSsr: true });
  const to899 = useMediaQuery("(max-width: 899px)", { noSsr: true });
  const from899 = useMediaQuery("(min-width: 899px)", { noSsr: true });
  const toLg = useMediaQuery("(max-width: 1439px)", { noSsr: true });
  const fromLg = useMediaQuery("(min-width: 1440px)", { noSsr: true });
  const to939 = useMediaQuery("(max-width: 939px)", { noSsr: true });
  const to1099 = useMediaQuery("(max-width: 1099px)", { noSsr: true });
  const from1099 = useMediaQuery("(min-width: 1099px)", { noSsr: true });
  const from1199 = useMediaQuery("(min-width: 1199px)", { noSsr: true });
  const to1920 = useMediaQuery("(max-width: 1920px)", { noSsr: true });
  const from1920 = useMediaQuery("(min-width: 1920px)", { noSsr: true });

  return {
    fromSm,
    toSm,
    toMd,
    fromMd,
    to899,
    from899,
    fromLg,
    toLg,
    to939,
    to1099,
    from1099,
    from1199,
    to1920,
    from1920
  };
};
