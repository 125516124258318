import { Dispatch, SetStateAction, useState } from "react";

import ScheduleDetailsCardContent from "../ScheduleDetailsCardContent/ScheduleDetailsCardContent";
import ScheduleDetailsButton from "../../ScheduleActions/ScheduleDetails/ScheduleDetailsButton";
import SingleDetailsAcordionItem from "../../AccordionDetailsCard/components/SingleDetailsAcordionItem";
import RemoveScheduleButton from "../../ScheduleActions/RemoveSchedule/RemoveScheduleButton";
import { ScheduleDto } from "../../../../../types";

import { MenuAnchorEl, TABLE_NAMES } from "../../../../../../../shared";

interface IVehicleScheduleDetailsSingleCardProps {
  schedule: ScheduleDto;
  index: number;
  totalCount: number;
  canManageSchedules: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
}

const VehicleScheduleDetailsSingleCard = ({
  schedule,
  index,
  totalCount,
  canManageSchedules,
  setIsLoading,
}: IVehicleScheduleDetailsSingleCardProps) => {
  const [isActionsOpen, setIsActionsOpen] = useState<MenuAnchorEl>(null);

  return (
    <SingleDetailsAcordionItem
      key={schedule.scheduleId}
      title={schedule.name}
      totalCount={totalCount}
      index={index}
      setIsLoading={setIsLoading}
      collapsable
      anchorEl={isActionsOpen}
      setIsActionsOpen={setIsActionsOpen}
      titleInlineActions={
        canManageSchedules && (
          <>
            <ScheduleDetailsButton
              schedule={schedule}
              onActionsMenu={setIsActionsOpen}
            />
            <RemoveScheduleButton
              schedule={schedule}
              onActionsMenu={setIsActionsOpen}
            />
          </>
        )
      }
    >
      <ScheduleDetailsCardContent
        schedule={schedule}
        resourceName={TABLE_NAMES.Vehicles}
        //lastDownloadAttempt and lastSuccessfulDownload are going to be shown only in quick schedule until the final solution in BE is cleared out. JIRA task: TACHO-2153

        lastDownloadAttempt={schedule?.quick}
        lastSuccessfulDownload={schedule?.quick}
        currentStatus={!schedule.quick}
      />
    </SingleDetailsAcordionItem>
  );
};

export default VehicleScheduleDetailsSingleCard;
