import { TFunction } from "i18next";

import {
  TABLE_NAMES,
  TablePageDataDto,
  useFormattedNumber,
} from "../../../../shared";

interface SchedulesMainDialogContentProps {
  isSelectedTab: boolean;
  isFilteredTab: boolean;
  isAllTab: boolean;
  resourceName: TABLE_NAMES;
  pageData: TablePageDataDto | null;
  resourceCount: number;
  t: TFunction<"translation", undefined>;
}

const useSchedulesMainDialogContent = ({
  isSelectedTab,
  isFilteredTab,
  isAllTab,
  resourceName,
  pageData,
  resourceCount,
  t,
}: SchedulesMainDialogContentProps) => {
  const { renderFormattedNumber } = useFormattedNumber();

  const generateBadgeText = () => {
    if (isSelectedTab) {
      return t("Dialog##selected resource", {
        resource: t(`General##${resourceName}`).toLocaleLowerCase(),
      });
    } else if (isFilteredTab) {
      return t("Dialog##filtered resource", {
        resource: t(`General##${resourceName}`).toLocaleLowerCase(),
      });
    } else if (isAllTab) {
      return t("Dialog##all resource", {
        resource: t(`General##${resourceName}`).toLocaleLowerCase(),
      });
    }
  };

  const generateItemsCountToDisplay = () => {
    if (isSelectedTab) {
      return `${renderFormattedNumber(resourceCount)}`;
    } else {
      return pageData?.totalCount;
    }
  };

  const generateSelectedTabDescription = () => {
    if (isSelectedTab) {
      return t(`Dialog##schedule apply to selected resource`, {
        resource: t(`General##${resourceName}`).toLocaleLowerCase(),
      });
    } else if (isFilteredTab) {
      return t(`Dialog##schedule apply to filtered resource`, {
        resource: t(`General##${resourceName}`).toLocaleLowerCase(),
      });
    } else if (isAllTab) {
      return t(`Dialog##schedule apply to all resource`, {
        resource: t(`General##${resourceName}`).toLocaleLowerCase(),
      });
    }
  };

  return {
    generateBadgeText,
    generateItemsCountToDisplay,
    generateSelectedTabDescription,
  };
};

export default useSchedulesMainDialogContent;
