import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../shared";

interface StyleProps {
  width?: string | number;
  colors?: ThemeColors;
  light?: boolean;
}

export const useStyles = ({ width, colors, light }: StyleProps) =>
  ({
    filterSeparator: {
      alignSelf: "stretch",
      flexShrink: "0",
      width: width,
      background: light ? colors?.gray200 : colors?.gray300,
      margin: "0 16px",
    },
  } as CSSObject);
