import { SvgIcon, SvgIconProps } from "@mui/material";

interface SelectedIconProps extends SvgIconProps {
currentColor?: string;
}

const SelectedIcon = ({currentColor, ...props }: SelectedIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.3339 9H3.33386C2.78386 9 2.33386 9.45 2.33386 10C2.33386 10.55 2.78386 11 3.33386 11H13.3339C13.8839 11 14.3339 10.55 14.3339 10C14.3339 9.45 13.8839 9 13.3339 9ZM13.3339 5H3.33386C2.78386 5 2.33386 5.45 2.33386 6C2.33386 6.55 2.78386 7 3.33386 7H13.3339C13.8839 7 14.3339 6.55 14.3339 6C14.3339 5.45 13.8839 5 13.3339 5ZM3.33386 15H9.33386C9.88386 15 10.3339 14.55 10.3339 14C10.3339 13.45 9.88386 13 9.33386 13H3.33386C2.78386 13 2.33386 13.45 2.33386 14C2.33386 14.55 2.78386 15 3.33386 15ZM22.5439 11.21L22.6339 11.3C23.0239 11.69 23.0239 12.32 22.6339 12.71L17.0539 18.3C16.6639 18.69 16.0339 18.69 15.6439 18.3L12.5539 15.21C12.1639 14.82 12.1639 14.19 12.5539 13.8L12.6439 13.71C13.0339 13.32 13.6639 13.32 14.0539 13.71L16.3539 16.01L21.1339 11.22C21.5139 10.82 22.1539 10.82 22.5439 11.21Z"
        fill={currentColor || "currentColor"}
      />
    </SvgIcon>
  );
};

export default SelectedIcon;
