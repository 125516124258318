/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { TFunction } from "i18next";

import { useStyles } from "../../../../../styles";

interface NotFoundResourceProps {
  t: TFunction<"translation", undefined>;
}

const NotFoundResource = ({ t }: NotFoundResourceProps) => {
  const { notFountToolDiv } = useStyles();

  return (
    <div data-testid="not-found-resource" css={css(notFountToolDiv)}>
      <Typography variant="body1" sx={{ marginBottom: "8px" }}>
        {t("Search##find tool##no vehicle found")}
      </Typography>
      <Typography variant="body2Regular">
        {t("Search##find tool##no vehicle found description")}
      </Typography>
    </div>
  );
};

export default NotFoundResource;
