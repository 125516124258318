export enum ApiResources {
  AcceptBetaVersionTerms = "Users/AcceptBetaVersionTerms",
  ChangePassword = "Auth/ChangePassword",
  ChangeSelectedCompany = "Auth/ChangeSelectedCompany",
  CompanyCards = "/CompanyCards",
  CompanyCardReaderFileVersions = "CompanyCardReaderFile/Versions",
  CompanyList = "Users/Companies",
  Companies = "companies",
  CompaniesDeleteConfirmation = "Companies/DeleteConfirmation",
  CompaniesLzCode = "Companies/LZ",
  CompaniesParent = "Companies/parent",
  CreateToken = "Auth/CreateToken",
  CreateTwoFactorToken = "Auth/CreateTwoFactorToken",
  CreateWebsocketToken = "Auth/CreateWebsocketToken",
  DashboardCompanyCards = "Dashboard/CompanyCards",
  DashboardCredits = "Dashboard/Credits",
  DashboardDevices = "Dashboard/Devices",
  DisableTwofactor = "Auth/DisableTwoFactor",
  DownloadVehicleFiles = "VehicleFiles/Download",
  DriverSchedules = "DriverSchedules",
  DriverSchedulesApply = "DriverSchedules/Apply",
  DriverSchedulesApplyToAll = "DriverSchedules/ApplyToAll",
  DriverSchedulesQuickDownload = "DriverSchedules/Quick",
  DriversImport = "Drivers/Import",
  EmailRecipients = "EmailRecipients",
  EnableTwofactor = "Auth/EnableTwoFactor",
  FindVehicle = "Vehicles/Find",
  GenerateTwoFactorKey = "Auth/GenerateTwoFactorKey",
  InviteUser = "Users/Invite",
  NewsItems = "NewsItems",
  RefreshToken = "Auth/RefreshToken",
  Repeat = "Repeat",
  ResetPassword = "Auth/ResetPassword",
  SendResetPasswordLink = "Auth/SendResetPasswordLink",
  Users = "Users",
  UsersCreateInvited = "Users/CreateInvited",
  UsersApiCredentials = "Users/ApiCredentials",
  UsersInvitationsCancel = "Users/Invitations/Cancel",
  UsersInvitationsValidate = "Users/Invitations/Validate",
  UsersInvitationsResend = "Users/Invitations/Resend",
  UsersMe = "Users/Me",
  UsersRoles = "Users/Roles",
  UsersSettings = "Users/Settings",
  UsersSettingsFileFormats = "Users/Settings/FileFormats",
  UsersTutorials = "Users/Tutorials",
  VehiclesImport = "Vehicles/Import",
  VehiclesLiveStatus = "Vehicles/LiveStatus",
  VehiclesLiveStatuses = "Vehicles/LiveStatuses",
  VehicleSchedules = "VehicleSchedules",
  VehicleSchedulesApply = "VehicleSchedules/Apply",
  VehicleSchedulesApplyToAll = "VehicleSchedules/ApplyToAll",
  VehicleSchedulesQuickDownload = "VehicleSchedules/Quick",
}

export enum AlertMessageResources {
  User = "User",
}
