/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { useEffect, useRef, useState } from "react";

import { DeviceStatusSummaryType } from "../../types";
import useDeviceStatusFunctions from "../../hooks/useDeviceStatusFunctions";
import { statusBorderColor, statusColor } from "../../functions";

import { useStyles } from "../../../../styles";
import DoughnutChartListItem from "../../../../components/DoughnutChartListItem/DoughnutChartListItem";

import FilterSeparator from "../../../../../../components/FilterSeparator/FilterSeparator";
import { COLORS, useMediaQueries } from "../../../../../../shared";
import Separator from "../../../../../../components/Separator/Separator";

interface SingleStatusInfoProps {
  infoData: DeviceStatusSummaryType;
}

const SingleStatusInfo = ({ infoData }: SingleStatusInfoProps) => {
  const { toSm } = useMediaQueries();
  const { renderCompaniesList, renderEmptyState } = useDeviceStatusFunctions();

  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  const {
    scrollableStatusInfoContainer,
    individualChartItemBox,
    bottomChadowBox,
    statusInfoListItem,
    singleStatusinfoBlock,
    divWithCenteredContent,
  } = useStyles({
    toSm,
  });

  useEffect(() => {
    if (scrollRef.current) {
      if (scrollRef.current.clientHeight === scrollRef.current.scrollHeight) {
        setScrolledToBottom(true);
      } else {
        setScrolledToBottom(false);
      }
    }
  }, [scrollRef?.current?.clientHeight, scrollRef?.current?.scrollHeight]);

  const isEmptyState = infoData.companies.length === 0;

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;

    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setScrolledToBottom(true);
    } else {
      setScrolledToBottom(false);
    }
  };

  return (
    <div
      css={css(singleStatusinfoBlock)}
      data-testid={`single-status-info-${infoData.name}`}
    >
      <div
        css={css([
          individualChartItemBox,
          { marginBottom: toSm ? "4px" : "unset" },
        ])}
      >
        <DoughnutChartListItem
          key={infoData.name}
          item={infoData}
          hoveredElement={null}
          hovered={false}
          backgroundColor={statusColor}
          borderColor={statusBorderColor}
        />
      </div>

      {toSm && (
        <Separator
          height="1px"
          color={COLORS.Gray200}
          customStyle={{ width: "calc(100% - 16px)" }}
        />
      )}

      {!toSm && (
        <FilterSeparator
          isInitialLoading={false}
          width={1}
          height={"100%"}
          styles={{ zIndex: 10 }}
          light
        />
      )}

      <div
        ref={scrollRef}
        onScroll={handleScroll}
        css={css([
          scrollableStatusInfoContainer,
          { marginTop: toSm ? "16px" : "unset" },
        ])}
      >
        {isEmptyState
          ? renderEmptyState(divWithCenteredContent as CSSObject)
          : renderCompaniesList(infoData, statusInfoListItem as CSSObject)}
      </div>
      {!scrolledToBottom && <div css={css(bottomChadowBox)}></div>}
    </div>
  );
};

export default SingleStatusInfo;
