import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { Actions } from "../types";

import useTableItemDetails from "../../../DetailsSection/hooks/useTableItemdetails";
import { ResourceParams } from "../../../Schedules/types";

import {
  DRIVERS_ACTIONS,
  getValueInSearchField,
  VEHICLES_ACTIONS,
} from "../../../../shared";
import { tableDetails } from "../../../../atoms/atoms";
import { refreshTableAndUpdateDetails } from "../../../../shared/functions/functions";
import { ALERT_STATUS } from "../../../../context/alert/types";

interface SchedulesMainDialogProps {
  resourceCount: number;
  isTutorialOpen: boolean;
  onClose: (action: Actions) => void;
  actionMain: VEHICLES_ACTIONS | DRIVERS_ACTIONS;
  resourceParams: ResourceParams;
  handleResponse: (
    status: ALERT_STATUS,
    message: string,
    description?: string
  ) => any;
}

const useSchedulesMainDialog = ({
  resourceCount,
  isTutorialOpen,
  onClose,
  actionMain,
  resourceParams,
  handleResponse,
}: SchedulesMainDialogProps) => {
  const { getTableItemDetails } = useTableItemDetails();
  const { t } = useTranslation();
  const currentTableDetails = useRecoilValue(tableDetails);

  const [
    isCloseDialogConfirmationOpen,
    setIsCloseDialogConfirmationOpen,
  ] = useState(false);

  const handleCloseSchedulesMainDialog = () => {
    if (resourceCount === 0 || isTutorialOpen) {
      onClose(actionMain);
    } else {
      setIsCloseDialogConfirmationOpen(true);
    }
  };

  const handleConfirmCloseDialog = (
    closeVehicleSchedules: boolean,
    closeConfirmOnly: boolean
  ) => {
    setIsCloseDialogConfirmationOpen(false);

    if (closeVehicleSchedules && !closeConfirmOnly) {
      onClose(actionMain);
    }
  };

  const checkIfItemDetailsAreOpen = (
    itemsArray: string[],
    currentDetails: any
  ) => itemsArray.includes(currentDetails?.id);

  const handleSuccess = (
    selectedtableRows: any,
    isVehicleSchedules: boolean,
    isAllTab: boolean,
    filteredItems: string[] = [],
    allItems: string[] = []
  ) => {
    const currentTableDetailsFromPage = isVehicleSchedules
      ? currentTableDetails?.vehiclesPage
      : currentTableDetails?.driversPage;

    const itemHasOpenedDetails =
      (currentTableDetails &&
        checkIfItemDetailsAreOpen(
          selectedtableRows,
          currentTableDetailsFromPage
        )) ||
      (currentTableDetails &&
        checkIfItemDetailsAreOpen(
          filteredItems,
          currentTableDetailsFromPage
        )) ||
      (currentTableDetails &&
        checkIfItemDetailsAreOpen(allItems, currentTableDetailsFromPage));

    const hasFilteredItems = filteredItems.length > 0;

    const countOfItemsToApply = hasFilteredItems
      ? filteredItems.length
      : resourceCount;

    const successMessage = isAllTab
      ? t(`Alert##apply all ${actionMain} success`)
      : t(`Alert##apply ${actionMain} success`, {
          resourceCount: countOfItemsToApply,
        });

    const valueInSearchField = getValueInSearchField(resourceParams);

    const matchingValue = itemHasOpenedDetails
      ? currentTableDetailsFromPage?.id
      : undefined;

    // refreshTableAndUpdateDetails method also deselects all rows
    refreshTableAndUpdateDetails({
      valueInSearchField,
      resourceId: matchingValue,
      successMessage,
      itemHasOpenedDetails,
      getTableItemDetails,
      handleResponse,
      leaveRowsChecked: false,
    });

    handleConfirmCloseDialog(true, false);
  };

  return {
    handleCloseSchedulesMainDialog,
    isCloseDialogConfirmationOpen,
    handleConfirmCloseDialog,
    handleSuccess,
  };
};

export default useSchedulesMainDialog;
