import { CSSObject } from "@emotion/react";
import { COLORS } from "../../../shared";

export const useStyles = (
  hideSteps?: boolean,

  highlightElementLikeSpotlight?: boolean
) => {
  const isApplyScheduleTutorialDesktop = highlightElementLikeSpotlight;

  return {
    spotlightCSS: {
      display: isApplyScheduleTutorialDesktop ? "none" : "undefined",
      outline: `3px solid ${COLORS.Blue700}`,
      backgroundColor: "transparent",
    },
    overlayCSS: {
      mixBlendMode: "normal" as const,
      backgroundColor: "transparent",
    },
    tutorialTooltipCSS: {
      padding: "16px",
      backgroundColor: COLORS.Gray700,
      width: "312px",
      borderRadius: "12px",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      color: COLORS.White,
    },
    tooltipText: {
      marginBottom: "8px",
      fontWeight: 600,
      display: "flex",

      "span:first-of-type": {
        color: hideSteps ? COLORS.White : COLORS.TextDarkDisabled,
        marginRight: "8px",
      },

      "span:last-of-type": {
        marginRight: "auto",
      },
    },
    closeIconCSS: {
      height: "20px",
      cursor: "pointer",
    },
    tooltipContent: {
      marginBottom: "16px",
      fontWeight: 400,
      whiteSpace: "pre-line",
    },
    buttonSpacing: {
      display: "flex",
      gap: "8px",
    },
    previousBtnCSS: {
      flex: "0 0 84px",
    },
    nextBtnCSS: {
      flex: "1",
    },
    companyCardAnimationCSS: {
      height: "156px",
      overflow: "hidden",
      borderRadius: "8px",
      marginBottom: "16px",

      "& > svg": {
        transform: "unset !important",
      },
    },
  } as CSSObject;
};
