import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import VehicleQuickDownloadScheduleDialogContent from "./VehicleQuickDownloadScheduleDialogContent";
import { QuickDownloadPayloadDto } from "../types";

import Dialog from "../../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../../Dialog/types/enums";
import { VehicleDataTypes, useMediaQueries } from "../../../../../../../shared";
import DialogActions from "../../../../../../../shared/components/buttons/DialogActions";

import { searchQueryParams } from "../../../../../../../atoms/atoms";
import { refreshTableAndUpdateDetails } from "../../../../../../../shared/functions/functions";
import useTableItemDetails from "../../../../../hooks/useTableItemdetails";
import useResponse from "../../../../../../../shared/hooks/useResponse";
import useAxiosPrivate from "../../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../../api/resources";
import { ALERT_STATUS } from "../../../../../../../context/alert/types";

interface VehicleQuickDownloadScheduleDialogProps {
  vehicleId: string;
  isOpen: boolean;
  handleClose: () => void;
}

const VehicleQuickDownloadScheduleDialog = ({
  vehicleId,
  isOpen,
  handleClose,
}: VehicleQuickDownloadScheduleDialogProps) => {
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  const { postData } = useAxiosPrivate();
  const { getTableItemDetails } = useTableItemDetails();
  const { handleCommonError, handleResponse } = useResponse();

  const searchParams = useRecoilValue(searchQueryParams);

  const [quickDownloadPayload, setQuickDownloadPayload] = useState<
    QuickDownloadPayloadDto
  >({
    vehicleId: vehicleId,
    fileTypes: [VehicleDataTypes.Overview],
    activities: null,
  });

  const [inputError, setInputError] = useState({
    startDate: "",
    endDate: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      await postData(
        ApiResources.VehicleSchedulesQuickDownload,
        quickDownloadPayload
      );

      handleContentRefresh();

      handleResponse(
        ALERT_STATUS.Info,
        t("Alert##quick schedule created##title"),
        t("Alert##quick schedule created##description##vehicles")
      );

      handleClose();
    } catch (error) {
      handleCommonError(error, t);
    }

    setIsLoading(false);
  };

  const handleContentRefresh = () => {
    refreshTableAndUpdateDetails({
      valueInSearchField: searchParams.vehiclesPage,
      resourceId: vehicleId,
      itemHasOpenedDetails: true,
      getTableItemDetails,
      handleResponse,
    });
  };

  const resetValueStates = () => {
    setQuickDownloadPayload({
      ...quickDownloadPayload,
      vehicleId: vehicleId,
      fileTypes: [VehicleDataTypes.Overview],
      activities: null,
    });

    setInputError({
      startDate: "",
      endDate: "",
    });
  };

  const anyErrorsExist =
    inputError.startDate !== "" || inputError.endDate !== "";

  return (
    <Dialog
      data-testid="vehicle-quick-schedule-dialog"
      title={t("Schedules##quick schedule##title")}
      description={t("Schedules##quick schedule##description")}
      open={isOpen}
      isTitleSeparator
      isActionsSeparator
      extendToMaxWidth={toMd}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      close={handleClose}
      submit={handleSubmit}
      TransitionProps={{
        onExited: () => resetValueStates(),
      }}
      actions={
        <DialogActions
          onClose={handleClose}
          isLoading={isLoading}
          isreadyToConfirm={!anyErrorsExist}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="vehicle-quick-schedule-save-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="vehicle-quick-schedule-close-button"
        />
      }
    >
      <VehicleQuickDownloadScheduleDialogContent
        quickDownloadPayload={quickDownloadPayload}
        inputError={inputError}
        anyErrorsExist={anyErrorsExist}
        setInputError={setInputError}
        setQuickDownloadPayload={setQuickDownloadPayload}
      />
    </Dialog>
  );
};

export default VehicleQuickDownloadScheduleDialog;
