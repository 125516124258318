import { createContext, useContext, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import { ALERT_STATUS } from "../alert/types";
import { TimeZoneContext } from "../TimeZone/TimeZoneProvider";
import { getTokensFromLocalStorage } from "../../shared/functions/auth";
import useAxiosPrivate from "../../api/hooks/useAxiosPrivate";
import {
  loginState,
  refreshTableState,
  takeMeThereState,
  tokensAtom,
  twoFactorVerificationState,
  userAtom,
  userSettingsAtom,
} from "../../atoms/atoms";
import useResponse from "../../shared/hooks/useResponse";
import { UserDto } from "../../Pages/Auth/types";
import { RESPONSE_MESSAGES } from "../../api/types";
import { ApiResources } from "../../api/resources";
import { USER_PERMISSIONS } from "../../roles/types/enums";
import { COOKIE_NAMES } from "../../shared";
import { Cookies } from "../../components/Privacy/types";

export const AuthContext = createContext<any>({});

interface IAuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: IAuthProviderProps) => {
  const { changeTimeZone, getSystemTimezone } = useContext(TimeZoneContext);

  const { handleCommonError, handleResponse } = useResponse();
  const { getData } = useAxiosPrivate();
  const { t } = useTranslation();
  const [cookies] = useCookies([COOKIE_NAMES.Analytics]);

  const [tokenInfo, setTokenInfo] = useRecoilState(tokensAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [isLoginState, setIsLoginState] = useRecoilState(loginState);
  const [userSettings, setUserSettings] = useRecoilState(userSettingsAtom);
  const [is2FaVerification, setIs2FaVerification] = useRecoilState(
    twoFactorVerificationState
  );
  const [isTakeMeThere, setIsTakeMeThere] = useRecoilState(takeMeThereState);
  const [, setIsRefreshTable] = useRecoilState(refreshTableState);

  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessageFromServer, setErrorMessageFromServer] = useState<boolean>(
    false
  );
  const [cookieConsentStatus, setCookieConsentStatus] = useState<Cookies>({
    [COOKIE_NAMES.Analytics]: String(cookies.analytics),
  });

  const initialized = useRef(false);

  const jwtToken = getTokensFromLocalStorage()?.token;

  const getUserData = async (getTimezone?: boolean) => {
    if (!initialized.current && jwtToken) {
      initialized.current = true;
      const controller = new AbortController();

      // display auth loader only if user is in login state
      isLoginState && setIsAuthLoading(true);

      await getData(ApiResources.UsersMe)
        .then((response: any) => {
          const user: UserDto = response?.data;
          setUser(user);
          setIsAuthLoading(false);
          isTakeMeThere && setIsRefreshTable(false);
          setIsTakeMeThere(false);

          return user;
        })
        .catch((error) => {
          const errorMessage =
            error.message === RESPONSE_MESSAGES.Status401
              ? t("General##unauthorized")
              : error.message;
          handleResponse(ALERT_STATUS.Critical, errorMessage);
        })
        .then((userData: UserDto | void) => {
          if (userData && getTimezone) {
            const timeZoneValue = userData?.timeSettings.timeZone
              ? userData?.timeSettings.timeZone
              : getSystemTimezone();

            changeTimeZone(timeZoneValue);
          }

          setIsAuthLoading(false);
          setIsLoginState(false);
        });

      initialized.current = false;
      controller.abort();
    }
  };

  const toggleTwoFaEnable = (value: boolean) => {
    if (user) {
      setUser({ ...user, twoFactorEnabled: value });
    } else {
      return null;
    }
  };

  const toggle2FaVerification = (value: boolean) => {
    setIs2FaVerification(value);
  };

  const getUserSettings = async () => {
    try {
      const { data } = await getData(ApiResources.UsersSettings);
      setUserSettings(data);

      return data;
    } catch (error) {
      handleCommonError(error, t);
    }
  };

  const getAccessStatus = (feature: USER_PERMISSIONS) =>
    user?.permissions.includes(feature);

  const changeCookieConsentStatus = (
    cookieName: COOKIE_NAMES,
    cookieValue: boolean
  ) => {
    const yearFromNowToExpire = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );

    const allCookieNames = Object.keys(cookieConsentStatus) as COOKIE_NAMES[];

    const generateCookie = (name: COOKIE_NAMES) =>
      `${name}=${cookieValue}; expires=${yearFromNowToExpire}`;

    const updateCookies = () => {
      if (cookieName === COOKIE_NAMES.All) {
        const allCookiesToSet: {
          [key in COOKIE_NAMES]?: string;
        } = {} as Cookies;

        allCookieNames.forEach((nameOfCookie) => {
          document.cookie = generateCookie(nameOfCookie);
          allCookiesToSet[nameOfCookie] = String(cookieValue);
        });

        setCookieConsentStatus((prevState) => ({
          ...prevState,
          ...allCookiesToSet,
        }));
      } else {
        document.cookie = generateCookie(cookieName);

        setCookieConsentStatus((prevState) => ({
          ...prevState,
          [cookieName]: String(cookieValue),
        }));
      }
    };

    updateCookies();
  };

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      cookieConsentStatus,
      isAuthLoading,
      setIsAuthLoading,
      isLoading,
      setIsLoading,
      user,
      userSettings,
      setUser,
      getAccessStatus,
      getUserData,
      getUserSettings,
      tokenInfo,
      setTokenInfo,
      errorMessageFromServer,
      setErrorMessageFromServer,
      toggleTwoFaEnable,
      toggle2FaVerification,
      is2FaVerification,
      changeCookieConsentStatus,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthLoading, isLoading, user, userSettings, getUserSettings, tokenInfo]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
