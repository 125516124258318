/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import VehicleGeneralDetails from "../components/GeneralDetails/VehicleGeneralDetails";
import DriverGeneralDetails from "../components/GeneralDetails/DriverGeneralDetails";
import CompaniesGeneralDetails from "../components/GeneralDetails/CompaniesGeneralDetails";
import ResourceScheduleCards from "../components/ResourceScheduleCards/ResourceScheduleCards";
import CompanyCardsDetails from "../components/CompanyCardsDetails/CompanyCardsDetails";
import EmailTransferDetails from "../components/EmailTransferDetails/EmailTransferDetails";

import BannerMessage, {
  BANNER_MESSAGE_STATUSES,
} from "../../../../BannerMessage/BannerMessage";

import { ROUTES } from "../../../../../shared";
import { emailTransferDetailsState } from "../../../../../atoms/atoms";

const useDetailsContent = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const emailTransferDetails = useRecoilValue(emailTransferDetailsState);

  const renderGeneralDetails = (detailsData: any) => {
    switch (location.pathname) {
      case ROUTES.Vehicles:
        return <VehicleGeneralDetails detailsData={detailsData} />;
      case ROUTES.Drivers:
        return <DriverGeneralDetails detailsData={detailsData} />;
      case ROUTES.Companies:
        return <CompaniesGeneralDetails detailsData={detailsData} />;

      default:
        return <></>;
    }
  };

  const renderSchedules = (detailsData: any, displayLoader: boolean) => {
    const validRoutes = [ROUTES.Vehicles, ROUTES.Drivers];

    if (!validRoutes.includes(location.pathname as ROUTES)) {
      return <></>;
    }

    return (
      <ResourceScheduleCards
        detailsData={detailsData}
        isLoading={displayLoader}
        pageLocation={location.pathname as ROUTES}
      />
    );
  };

  const renderCompanyCards = (detailsData: any, displayLoader: boolean) =>
    location.pathname === ROUTES.Companies ? (
      <CompanyCardsDetails
        detailsData={detailsData}
        isLoading={displayLoader}
      />
    ) : (
      <></>
    );

  const renderFileSyncContent = () => {
    return (
      <BannerMessage
        data-testid="quick-schedule-message"
        status={BANNER_MESSAGE_STATUSES.Info}
        title={t("Details##Feature not available in BETA version")}
        css={css({ margin: "24px 16px 24px 24px" })}
      />
    );
  };

  const renderEmailTransferContent = (companyId: string) => {
    return (
      emailTransferDetails && (
        <EmailTransferDetails
          emailTransferDetails={emailTransferDetails}
          companyId={companyId}
        />
      )
    );
  };

  return {
    renderFileSyncContent,
    renderGeneralDetails,
    renderSchedules,
    renderCompanyCards,
    renderEmailTransferContent,
  };
};

export default useDetailsContent;
