/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { Typography } from "@mui/material";
import { TFunction } from "i18next";

import { useStyles } from "../../../../../styles";

import { useMediaQueries } from "../../../../../../../shared";
import CloseButton from "../../../../../../../shared/components/buttons/CloseButton";

interface FindToolTopControlsProps {
  t: TFunction<"translation", undefined>;
  close: () => void;
}

const FindToolTopControls = ({ t, close }: FindToolTopControlsProps) => {
  const { fromMd } = useMediaQueries();

  const { navbarItemTopControls, navbarItemDialogTitle } = useStyles();

  return (
    <div
      data-testid="find-tool-top-controls"
      css={css([
        navbarItemTopControls,
        {
          padding: "16px 16px 12px 0",
        },
      ])}
    >
      <Typography variant="h6" sx={navbarItemDialogTitle as CSSObject}>
        {t("Search##find tool##find tool")}
      </Typography>

      {fromMd && (
        <CloseButton onClose={close} customStyle={{ right: "-10px" }} />
      )}
    </div>
  );
};

export default FindToolTopControls;
