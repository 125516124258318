/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { TFunction } from "i18next";
import { useNavigate } from "react-router-dom";

import { FIND_VEHICLE_DTO_TYPE } from "../../../../../types";

import TextLink from "../../../../../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../../../../TextLink/types";
import {
  isStoredPageParams,
  LOCAL_STORAGE_KEYS,
  ROUTES,
  setPageParamsToLocalStorage,
  updateQueryParamsInLocalStorage,
} from "../../../../../../../shared";
import { useRecoilState } from "recoil";
import {
  findToolSearch,
  searchQueryParams,
} from "../../../../../../../atoms/atoms";
import { getPageGlobalStateObjectValue } from "../../../../../../Table/functions";

import { SearchQueryParamsStateType } from "../../../../../../../atoms/types";
import useTutorialMode from "../../../../../../TutorialMode/hooks/useTutorialMode";

interface FoundResourceDetailsProps {
  resourceData: FIND_VEHICLE_DTO_TYPE;
  t: TFunction<"translation", undefined>;
  onCloseDialog: () => void;
}

const FoundResourceDetails = ({
  resourceData,
  t,
  onCloseDialog,
}: FoundResourceDetailsProps) => {
  const navigate = useNavigate();
  const { handleCloseDetailsCompletely } = useTutorialMode();

  const [, setSearchParams] = useRecoilState(searchQueryParams);
  const [, setIsFindToolSearch] = useRecoilState(findToolSearch);

  const generateDetailsSingleRow = (title: string, value: string) => (
    <div css={css({ display: "flex", marginBottom: "8px" })}>
      <Typography variant="body2" sx={{ width: "136px" }}>
        {title}:
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </div>
  );

  const handleGoToVehicles = () => {
    const searchValue = resourceData.vehicleNumber;
    const pageLocation = ROUTES.Vehicles;

    // if page params are not stored in local storage, store them
    if (!isStoredPageParams(pageLocation)) {
      setPageParamsToLocalStorage({
        pathname: pageLocation,
        currentPage: "1",
        rowsPerPage: "25",
        selectedTableItem: null,
        searchQueryParams: searchValue,
        desktopDetailsSection: undefined,
        filters: null,
      });
    } else {
      updateQueryParamsInLocalStorage(
        pageLocation,
        LOCAL_STORAGE_KEYS.Contains,
        searchValue
      );
    }

    setSearchParams((prev: SearchQueryParamsStateType) =>
      getPageGlobalStateObjectValue(pageLocation, prev, searchValue)
    );
    setIsFindToolSearch(true);

    navigate(pageLocation);
    // close details and dialog
    handleCloseDetailsCompletely(pageLocation);
    onCloseDialog();
  };

  return (
    <div css={css({ marginTop: "14px" })} data-testid="found-resource-details">
      {generateDetailsSingleRow(
        t("Dialog##vehicle number"),
        resourceData.vehicleNumber
      )}
      {generateDetailsSingleRow(t("Dialog##company"), resourceData.companyName)}
      {generateDetailsSingleRow(
        t("Dialog##client company"),
        resourceData.clientCompanyName
      )}
      <TextLink
        size={TEXT_LINK_SIZES.Normal}
        onClick={handleGoToVehicles}
        customStyle={{ textDecoration: "none" }}
      >
        {t("Actions##go to vehicle")}
      </TextLink>
    </div>
  );
};

export default FoundResourceDetails;
