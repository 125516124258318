/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";
import { Typography } from "@mui/material";
import { i18n, TFunction } from "i18next";

import { ErrorListMessagesEntryType } from "../types";
import { checkIfTranslationExists } from "../utils";

interface BannerFunctionsProps {
  i18n: i18n;
  t: TFunction<"translation", undefined>;
}

const useBannerFunctions = ({ i18n, t }: BannerFunctionsProps) => {
  const maxErrorInitialItemsCount = 2;
  const translationObject = "Alert";

  const translateEntryField = (entry: ErrorListMessagesEntryType) => {
    // translate the field key if translation exists, or display raw field key
    const fieldKey = checkIfTranslationExists(
      entry.field.toLowerCase(),
      "Table",
      i18n
    );
    t(fieldKey);
    return `${t("General##column")}: ${t(fieldKey)}. `;
  };

  const generateSingleErrorEntry = (entry: ErrorListMessagesEntryType) => (
    <li
      key={uuidv4()}
      css={css({
        display: "list-item !important",
        listStyle: "disc !important",
      })}
    >
      <Typography component="span" variant="body3regular">
        {checkIfTranslationExists(entry.message, translationObject, i18n)}
      </Typography>
      <br />
      <Typography component="span" variant="body3regular">
        {entry.field.length > 0 ? translateEntryField(entry) : null}
        {`${t("General##row")}: ${entry.rows}`}
      </Typography>
    </li>
  );

  const generateErrorBannerEntries = (errorEntries: any) =>
    errorEntries?.map((entry: ErrorListMessagesEntryType) =>
      generateSingleErrorEntry(entry)
    );

  const generateCollapsedDescription = (errorEntries: any) =>
    errorEntries?.map((entry: ErrorListMessagesEntryType, index: number) => {
      return index + 1 <= maxErrorInitialItemsCount
        ? generateSingleErrorEntry(entry)
        : null;
    });

  const generateBannerDescriptionEntries = (
    generateItems: (errorEntries: any) => any,
    errorEntries: any
  ) => {
    return (
      <ul
        css={css({
          marginLeft: "1rem",
        })}
      >
        {generateItems(errorEntries)}
      </ul>
    );
  };

  return {
    generateSingleErrorEntry,
    generateErrorBannerEntries,
    generateCollapsedDescription,
    maxErrorInitialItemsCount,
    generateBannerDescriptionEntries,
  };
};

export default useBannerFunctions;
