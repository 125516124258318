import {
  ALL_ROUTES,
  COLUMN_NAMES,
  COLUMN_RESOURCES,
  Column,
  DRIVERS_ACTIONS,
  DetailsDataValue,
  LOCAL_STORAGE_KEYS,
  PageParams,
  ROUTES,
  SetPageParamsToLocalStorageDto,
  TABLE_NAMES,
  VEHICLES_ACTIONS,
} from "..";
import { setItemToLocalStorage } from "../functions/functions";
import { MIN_CHAR_LENGTH_IN_SEARCH } from "../../components/Search/constants";
import { ResourceParams } from "../../components/Schedules/types";
import { ApiResources } from "../../api/resources";
import { FILTER_KEYS, FILTER_NAMES } from "../../components/Filters/types";

const localStorageKeysFromRoutes: { [key: string]: string } = {
  [ROUTES.Vehicles]: LOCAL_STORAGE_KEYS.VehiclesPageParams,
  [ROUTES.Drivers]: LOCAL_STORAGE_KEYS.DriversPageParams,
  [ROUTES.VehicleFiles]: LOCAL_STORAGE_KEYS.VehicleFilesPageParams,
  [ROUTES.DriverFiles]: LOCAL_STORAGE_KEYS.DriverFilesPageParams,
  [ROUTES.Users]: LOCAL_STORAGE_KEYS.UsersPageParams,
  [ROUTES.UsersInvitations]: LOCAL_STORAGE_KEYS.UsersInvitationsPageParams,
  [ROUTES.Companies]: LOCAL_STORAGE_KEYS.CompaniesPageParams,
};

export const getDetailsDataValues = (
  tableName: TABLE_NAMES,
  filterId: string | undefined,
  filterName: string | undefined,
  deviceStatus?: boolean
) => {
  const filterValueObject = {
    id: filterId,
    name: filterName,
  };

  const detailsDataValues: { [key: string]: DetailsDataValue } = {
    [TABLE_NAMES.Vehicles]: {
      filterValue: filterValueObject,
      pageLocation: deviceStatus ? ROUTES.Vehicles : ROUTES.VehicleFiles,
      filterName: deviceStatus
        ? FILTER_NAMES.Statuses
        : FILTER_NAMES.VehicleNumber,
    },
    [TABLE_NAMES.Drivers]: {
      filterValue: filterValueObject,
      pageLocation: ROUTES.DriverFiles,
      filterName: FILTER_NAMES.DriverName,
    },
  };

  return detailsDataValues[tableName];
};

const checkLocalStorageKey = (pathname: string) =>
  localStorageKeysFromRoutes[pathname] || "";

export const getPageNumberFromLocalStorage = (pathname: string) => {
  const localStorageKey = checkLocalStorageKey(pathname);

  return localStorageKey
    ? JSON.parse(localStorage.getItem(localStorageKey) as any)?.pageNumber
    : null;
};

export const getRowsPerPageFromLocalStorage = (pathname: string) => {
  const localStorageKey = checkLocalStorageKey(pathname);

  return localStorageKey
    ? JSON.parse(localStorage.getItem(localStorageKey) as any)?.showPerPage
    : null;
};

export const getSortedColumnFromLocalStorage = (pathname: string) => {
  const localStorageKey = checkLocalStorageKey(pathname);

  return localStorageKey
    ? JSON.parse(localStorage.getItem(localStorageKey) as any)?.sortedColumn
    : null;
};

export const getSelectedTableItemFromLocalStorage = (pathname: string) => {
  const localStorageKey = checkLocalStorageKey(pathname);

  return localStorageKey
    ? JSON.parse(localStorage.getItem(localStorageKey) as any)
        ?.selectedTableItem
    : null;
};

export const getSearchQueryParamsFromLocalStorage = (pathname: string) => {
  const localStorageKey = checkLocalStorageKey(pathname);

  return localStorageKey
    ? JSON.parse(localStorage.getItem(localStorageKey) as any)?.contains
    : null;
};

export const getDetailsSectionStateFromLocalStorage = (pathname: string) => {
  const localStorageKey = checkLocalStorageKey(pathname);

  if (localStorageKey !== "") {
    return JSON.parse(localStorage.getItem(localStorageKey) as any)
      ?.desktopDetailsSection;
  } else {
    return null;
  }
};

export const getFiltersFromLocalStorage = (pathname: string) => {
  const localStorageKey = checkLocalStorageKey(pathname);

  if (localStorageKey !== "") {
    return JSON.parse(localStorage.getItem(localStorageKey) as any)?.filters;
  } else {
    return null;
  }
};

export const setPageParamsToLocalStorage = (
  pageParams: SetPageParamsToLocalStorageDto
) => {
  const {
    pathname,
    currentPage,
    rowsPerPage,
    selectedTableItem,
    searchQueryParams,
    desktopDetailsSection,
    filters,
    sortedColumn,
  } = pageParams;

  const pageKey = checkLocalStorageKey(pathname);

  if (pageKey) {
    const params: any = {
      page: pathname,
      pageNumber: currentPage,
      showPerPage: rowsPerPage,
      selectedTableItem,
      contains: searchQueryParams,
      desktopDetailsSection,
      filters,
    };

    if (sortedColumn !== undefined) {
      params.sortedColumn = sortedColumn;
    }

    return localStorage.setItem(pageKey, JSON.stringify(params));
  }
};

export const isStoredPageParams = (pathname: string) => {
  return Boolean(localStorage.getItem(localStorageKeysFromRoutes[pathname]));
};

export const deleteStoredPageParams = () => {
  const localStorageKeysToRemove = [
    LOCAL_STORAGE_KEYS.VehiclesPageParams,
    LOCAL_STORAGE_KEYS.DriversPageParams,
    LOCAL_STORAGE_KEYS.VehicleFilesPageParams,
    LOCAL_STORAGE_KEYS.DriverFilesPageParams,
    LOCAL_STORAGE_KEYS.UsersPageParams,
    LOCAL_STORAGE_KEYS.UsersInvitationsPageParams,
    LOCAL_STORAGE_KEYS.CompaniesPageParams,
  ];

  localStorageKeysToRemove.forEach((key) => localStorage.removeItem(key));
};

const pagesWithTableView = [TABLE_NAMES.Vehicles, TABLE_NAMES.Drivers];

export const deleteNotExistingLocalStorageKeys = () => {
  const possibleLocalStorageKeys = Object.values(LOCAL_STORAGE_KEYS);
  const currentLocalStorageKeys = Object.keys(localStorage);

  // vehicles and drivers page have separate table views,
  // but the same LOCAL_STORAGE_KEYS.ActiveTableColumns enum is used for both by combining the key with the page name
  let renamedKeys: any[] = [];

  possibleLocalStorageKeys.forEach((key) => {
    if (key === LOCAL_STORAGE_KEYS.ActiveTableColumns) {
      pagesWithTableView.forEach((page) => {
        renamedKeys.push(`${key}-${page}` as any);
      });
    }
  });

  // combine possible keys with renamed keys
  const arrayToCheck = possibleLocalStorageKeys.concat(renamedKeys);

  currentLocalStorageKeys.forEach((key: any) => {
    // if key is not in the array of possible keys, remove it
    if (!arrayToCheck.includes(key)) {
      localStorage.removeItem(key);
    }
  });
};

const isPageParamsNotNull = (pageParams: PageParams) => pageParams !== null;

const definedPageParams = (pageParams: PageParams) => pageParams !== undefined;

const pageParamsExist = (pageParams: PageParams) =>
  isPageParamsNotNull(pageParams) || definedPageParams(pageParams);

export const updateQueryParamsInLocalStorage = (
  pathname: string,
  updateKey: LOCAL_STORAGE_KEYS,
  updateValue: any
) => {
  const key = localStorageKeysFromRoutes[pathname];
  if (key) {
    const pageParams = localStorage.getItem(key) as any;
    if (pageParamsExist(pageParams)) {
      let params = JSON.parse(pageParams);
      params = { ...params, [updateKey]: updateValue };
      localStorage.setItem(key, JSON.stringify(params));
    }
  }
};

export const updateSelectedProperty = (
  passedProperty: LOCAL_STORAGE_KEYS,
  params: any,
  value: any
) => {
  switch (passedProperty) {
    case LOCAL_STORAGE_KEYS.SelectedTableItem:
      return { ...params, selectedTableItem: value };
    case LOCAL_STORAGE_KEYS.DesktopDetailsSection:
      return { ...params, desktopDetailsSection: value };
    case LOCAL_STORAGE_KEYS.Contains:
      return { ...params, contains: value };
    case LOCAL_STORAGE_KEYS.Page:
      return { ...params, page: value };
    case LOCAL_STORAGE_KEYS.PageNumber:
      return { ...params, pageNumber: value };
    case LOCAL_STORAGE_KEYS.ShowPerPage:
      return { ...params, showPerPage: value };
    default:
      return;
  }
};

const routeIsValid = (pathname: string, routeOption: string) =>
  pathname === routeOption || pathname === ALL_ROUTES.AllRoutes;

const setLocalStorageParams = (
  pageParams: string | null,
  passedProperty: any,
  value: any,
  localStorageKey: LOCAL_STORAGE_KEYS,
  isObjectValue?: boolean
) => {
  if (pageParams) {
    let params = JSON.parse(pageParams);
    params = updateSelectedProperty(passedProperty, params, value);
    !isObjectValue
      ? setItemToLocalStorage(localStorageKey, JSON.stringify(params))
      : setItemToLocalStorage(localStorageKey, JSON.stringify(value));
  }
};

const setSpecificPageParams = (
  isSpecificPageParams: boolean,
  localStorageKey: LOCAL_STORAGE_KEYS,
  passedProperty: any,
  value: any,
  isObjectValue?: boolean
) => {
  if (isSpecificPageParams) {
    const pageParams = localStorage.getItem(localStorageKey) as any;
    setLocalStorageParams(
      pageParams,
      passedProperty,
      value,
      localStorageKey,
      isObjectValue
    );
  }
};

export const updatePageParamsStateInLocalStorage = (
  pathname: string,
  passedProperty: any,
  value: any,
  isObjectValue?: boolean
) => {
  const isVehicleParamsInLocalStorage = Boolean(
    localStorage.getItem(LOCAL_STORAGE_KEYS.VehiclesPageParams)
  );
  const isDriversParamsInLocalStorage = Boolean(
    localStorage.getItem(LOCAL_STORAGE_KEYS.DriversPageParams)
  );
  const isVehicleFilesParamsInLocalStorage = Boolean(
    localStorage.getItem(LOCAL_STORAGE_KEYS.VehicleFilesPageParams)
  );
  const isDriverFilesParamsInLocalStorage = Boolean(
    localStorage.getItem(LOCAL_STORAGE_KEYS.DriverFilesPageParams)
  );
  const isUsersParamsInLocalStorage = Boolean(
    localStorage.getItem(LOCAL_STORAGE_KEYS.UsersPageParams)
  );
  const isUsersInvitationsParamsInLocalStorage = Boolean(
    localStorage.getItem(LOCAL_STORAGE_KEYS.UsersInvitationsPageParams)
  );
  const isCompaniesParamsInLocalStorage = Boolean(
    localStorage.getItem(LOCAL_STORAGE_KEYS.CompaniesPageParams)
  );

  if (routeIsValid(pathname, ROUTES.Vehicles)) {
    setSpecificPageParams(
      isVehicleParamsInLocalStorage,
      LOCAL_STORAGE_KEYS.VehiclesPageParams,
      passedProperty,
      value,
      isObjectValue
    );
  }
  if (routeIsValid(pathname, ROUTES.Drivers)) {
    setSpecificPageParams(
      isDriversParamsInLocalStorage,
      LOCAL_STORAGE_KEYS.DriversPageParams,
      passedProperty,
      value
    );
  }

  if (routeIsValid(pathname, ROUTES.VehicleFiles)) {
    setSpecificPageParams(
      isVehicleFilesParamsInLocalStorage,
      LOCAL_STORAGE_KEYS.VehicleFilesPageParams,
      passedProperty,
      value
    );
  }

  if (routeIsValid(pathname, ROUTES.DriverFiles)) {
    setSpecificPageParams(
      isDriverFilesParamsInLocalStorage,
      LOCAL_STORAGE_KEYS.DriverFilesPageParams,
      passedProperty,
      value
    );
  }

  if (routeIsValid(pathname, ROUTES.Users)) {
    setSpecificPageParams(
      isUsersParamsInLocalStorage,
      LOCAL_STORAGE_KEYS.UsersPageParams,
      passedProperty,
      value
    );
  }

  if (routeIsValid(pathname, ROUTES.UsersInvitations)) {
    setSpecificPageParams(
      isUsersInvitationsParamsInLocalStorage,
      LOCAL_STORAGE_KEYS.UsersInvitationsPageParams,
      passedProperty,
      value
    );
  }

  if (routeIsValid(pathname, ROUTES.Companies)) {
    setSpecificPageParams(
      isCompaniesParamsInLocalStorage,
      LOCAL_STORAGE_KEYS.CompaniesPageParams,
      passedProperty,
      value
    );
  }
};

const getIsActiveColumnFromLocalStorage = (
  name: TABLE_NAMES,
  columnName: string,
  defaultValue: boolean | undefined
) => {
  const isJson = (columnsFromStorage: string) => {
    try {
      const parsedColumns = JSON.parse(columnsFromStorage);
      return Object.keys(parsedColumns).length > 0;
    } catch {
      return false;
    }
  };

  const columns = localStorage.getItem(
    `${LOCAL_STORAGE_KEYS.ActiveTableColumns}-${name}`
  );

  if (columns) {
    return isJson(columns) ? JSON.parse(columns)[columnName] : defaultValue;
  }

  return defaultValue;
};

export const renderColumnsWithIsActive = (
  name: TABLE_NAMES,
  initialColumns: Column[]
) => {
  const columnsWithIsActive = addIsActiveToColumns(initialColumns);

  return columnsWithIsActive.map((column: Column) => ({
    ...column,
    isActive: getIsActiveColumnFromLocalStorage(
      name,
      column.name,
      column.isActive
    ),
  }));
};

export const addIsActiveToColumns = (columns: Column[]) =>
  columns.map((column) => ({
    ...column,
    isActive: Boolean(column.isDefaultActive),
  }));

export const getValueInSearchField = (location: any) =>
  getSearchQueryParamsFromLocalStorage(location.pathname)?.length >=
  MIN_CHAR_LENGTH_IN_SEARCH
    ? getSearchQueryParamsFromLocalStorage(location.pathname)
    : null;

export const tableNameParams: { [key: string]: ResourceParams } = {
  [TABLE_NAMES.Vehicles]: {
    resourceName: TABLE_NAMES.Vehicles,
    actionCreate: VEHICLES_ACTIONS.CreateSchedule,
    actionDelete: VEHICLES_ACTIONS.DeleteSchedule,
    actionEdit: VEHICLES_ACTIONS.EditSchedule,
    actionMain: VEHICLES_ACTIONS.Schedules,
    actionRemove: VEHICLES_ACTIONS.RemoveSchedules,
    apiResource: ApiResources.VehicleSchedules,
    apiResourceApply: ApiResources.VehicleSchedulesApply,
    apiResourceApplyToAll: ApiResources.VehicleSchedulesApplyToAll,
    column: COLUMN_NAMES.Vehicle,
    filterKey: FILTER_KEYS.VehicleIds,
    pathname: ROUTES.Vehicles,
  },
  [TABLE_NAMES.Drivers]: {
    resourceName: TABLE_NAMES.Drivers,
    actionCreate: DRIVERS_ACTIONS.CreateSchedule,
    actionDelete: DRIVERS_ACTIONS.DeleteSchedule,
    actionEdit: DRIVERS_ACTIONS.EditSchedule,
    actionMain: DRIVERS_ACTIONS.Schedules,
    actionRemove: DRIVERS_ACTIONS.RemoveSchedules,
    apiResource: ApiResources.DriverSchedules,
    apiResourceApply: ApiResources.DriverSchedulesApply,
    apiResourceApplyToAll: ApiResources.DriverSchedulesApplyToAll,
    column: COLUMN_NAMES.Driver,
    filterKey: FILTER_KEYS.DriverIds,
    pathname: ROUTES.Drivers,
  },
};

export const convertStringBasedOnLength = (
  resourceLength: number,
  inputString: string
) => {
  const isMultipleSelected = resourceLength > 1;

  return isMultipleSelected
    ? `${inputString} multiple`
    : `${inputString} single`;
};

export const checkIfFilesPage = (currPage: string) =>
  currPage === ROUTES.VehicleFiles || currPage === ROUTES.DriverFiles;

export const getDefaultSortedColumn = (
  currPage: string,
  dataFromLocalStorage: any
) => {
  if (checkIfFilesPage(currPage)) {
    return (
      dataFromLocalStorage || {
        orderBy: COLUMN_RESOURCES.DownloadTime,
        descending: true,
      }
    );
  }

  return dataFromLocalStorage;
};

export const checkIfTranslationExists = (
  key: string,
  translationObject: string,
  i18n: any
) =>
  i18n.exists(`${translationObject}##${key}`)
    ? i18n.t(`${translationObject}##${key}`)
    : key;

// Clear cache data
export const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};

export const storePageFilterParamsIntoLocalStorage = (
  pageLocation: ROUTES,
  newFilterParams: {
    [x: string]: any[];
  }
) => {
  if (!isStoredPageParams(pageLocation)) {
    setPageParamsToLocalStorage({
      pathname: pageLocation,
      currentPage: "1",
      rowsPerPage: "25",
      selectedTableItem: null,
      searchQueryParams: null,
      desktopDetailsSection: undefined,
      filters: newFilterParams,
    });
  } else {
    updateQueryParamsInLocalStorage(
      pageLocation,
      LOCAL_STORAGE_KEYS.Filters,
      newFilterParams
    );
  }
};
