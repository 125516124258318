import Link, { LinkProps } from "@mui/material/Link";
import { useContext } from "react";
import { LinkProps as RouterLinkProps } from "react-router-dom";
import RightIcon from "@mui/icons-material/ChevronRightRounded";
import { CSSObject } from "@emotion/react";

import { TEXT_LINK_COLORS, TEXT_LINK_SIZES } from "./types";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

export interface TextLinkProps {
  children?: React.ReactNode;
  size: TEXT_LINK_SIZES;
  color?: TEXT_LINK_COLORS;
  isIcon?: boolean;
  isDisabled?: boolean;
  component?: any;
  noFlex?: boolean;
  customStyle?: {};
}

const TextLink = ({
  children,
  size,
  color = TEXT_LINK_COLORS.Primary,
  isIcon,
  isDisabled,
  component,
  noFlex,
  customStyle,
  ...props
}: TextLinkProps & LinkProps & Partial<RouterLinkProps>) => {
  const { colors } = useContext(ThemeContext);

  const styleOptions = {
    color: {
      default:
        color === TEXT_LINK_COLORS.Primary ? colors.blue700 : colors.red800,
      hover:
        color === TEXT_LINK_COLORS.Primary ? colors.blue800 : colors.red800,
      active:
        color === TEXT_LINK_COLORS.Primary ? colors.blue900 : colors.red900,
    },
    fontSize: {
      [TEXT_LINK_SIZES.Small]: "12px",
      [TEXT_LINK_SIZES.Normal]: "14px",
      [TEXT_LINK_SIZES.Large]: "16px",
    },
    lineHeight: {
      [TEXT_LINK_SIZES.Small]: "16px",
      [TEXT_LINK_SIZES.Normal]: "20px",
      [TEXT_LINK_SIZES.Large]: "20px",
    },
    letterSpacing: {
      [TEXT_LINK_SIZES.Small]: "0.4px",
      [TEXT_LINK_SIZES.Normal]: "0.1px",
      [TEXT_LINK_SIZES.Large]: "0.15px",
    },
  };

  const colorDefault = isDisabled
    ? colors.textDarkDisabled
    : styleOptions.color.default;

  const colorHover = styleOptions.color.hover;
  const colorActive = styleOptions.color.active;
  const fontSize = styleOptions.fontSize[size];
  const lineHeight = styleOptions.lineHeight[size];
  const letterSpacing = styleOptions.letterSpacing[size];

  return (
    <Link
      component={component}
      {...props}
      rel="noreferrer"
      sx={[
        {
          display: noFlex ? "initial" : "inline-flex",
          alignItems: "center",
          cursor: "pointer",
          fontWeight: "600",
          color: colorDefault,
          textDecorationColor: "inherit",
          pointerEvents: isDisabled ? "none" : "auto",
          fontSize,
          lineHeight,
          letterSpacing,
          transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

          "& > svg": {
            fontSize: "16px",
            marginLeft: "2px",
          },

          "&:hover": {
            color: colorHover,
          },

          "&:active": {
            color: colorActive,
          },
        },
        customStyle as CSSObject,
      ]}
    >
      {children}

      {isIcon && <RightIcon />}
    </Link>
  );
};

export default TextLink;
