/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import { useTranslation } from "react-i18next";
import { SetStateAction, useState } from "react";

import DesktopFindTool from "./components/DesktopFindTool/DesktopFindTool";
import MobileFindTool from "./components/MobileFindTool/MobileFindTool";

import { useStyles } from "../../styles";

import Button from "../../../Button/Button";

import { MenuAnchorEl, useMediaQueries } from "../../../../shared";

const FindTool = () => {
  const { to899, toMd } = useMediaQueries();
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState<MenuAnchorEl>(null);
  const [isMobileDialogOpen, setIsMobileDialogOpen] = useState<boolean>(false);

  const { navbarButtonTitle } = useStyles();

  const handleOpenMobileDialog = () => {
    setIsMobileDialogOpen(true);
  };

  const handleOpenDesktopDialog = (e: {
    currentTarget: SetStateAction<MenuAnchorEl>;
  }) => {
    setOpenDialog(e.currentTarget);
  };

  const handleCloseDesktopDialog = () => {
    setOpenDialog(null);
  };

  const handleCloseMobileDialog = () => {
    setIsMobileDialogOpen(false);
  };

  return (
    <>
      {/* button turns into icon only on wider screen then to Md, but mobile dialog is shown only toMd screen */}
      {toMd || to899 ? (
        <Button
          data-testid="find-tool-button-to-899"
          variant="iconOnly"
          size="small"
          color="white"
          onClick={toMd ? handleOpenMobileDialog : handleOpenDesktopDialog}
          icon={<TravelExploreOutlinedIcon />}
          css={css({ marginleft: "4px" })}
        />
      ) : (
        <Button
          data-testid="find-tool-button-from-899"
          color="white"
          variant="iconLeft"
          size="small"
          icon={<TravelExploreOutlinedIcon />}
          onClick={handleOpenDesktopDialog}
        >
          <Typography variant="body2" css={css(navbarButtonTitle)}>
            {t("Button##find tool")}
          </Typography>
        </Button>
      )}
      {toMd ? (
        <MobileFindTool
          isOpen={isMobileDialogOpen}
          onClose={handleCloseMobileDialog}
        />
      ) : (
        <DesktopFindTool
          onClose={handleCloseDesktopDialog}
          anchorEl={openDialog}
        />
      )}
    </>
  );
};

export default FindTool;
