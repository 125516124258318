/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

import useDeviceStatus from "./hooks/useDeviceStatus";
import DoughnutChart from "./components/DoughnutChart/DoughnutChart";
import {
  hoveredElementToRealIndex,
  statusBorderColor,
  statusColor,
} from "./functions";
import { DeviceStatusSummaryType } from "./types";
import SingleStatusInfo from "./components/SingleStatusInfo/SingleStatusInfo";

import CardWrapper from "../../CardWrapper/CardWrapper";
import DoughnutChartListItem from "../../components/DoughnutChartListItem/DoughnutChartListItem";
import { useStyles as dashboardStyles } from "../../styles";

import {
  deviceStatusState,
  tableLoading,
  takeMeThereState,
} from "../../../../atoms/atoms";
import RectangleLoader from "../../../../shared/components/loaders/RectangleLoader";
import { useMediaQueries } from "../../../../shared";
import GoBack from "../../../../components/Actions/GoBack/GoBack";

const DeviceStatus = () => {
  const { isLoading: isDeviceStatusLoading } = useDeviceStatus();
  const { toSm } = useMediaQueries();

  const { t } = useTranslation();

  const isTableLoading = useRecoilValue(tableLoading);
  const deviceStatus = useRecoilValue(deviceStatusState);
  const isTakeMeThere = useRecoilValue(takeMeThereState);

  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredElement, setHoveredElement] = useState<number | null>(null);
  const [hoveredListItem, setHoveredListItem] = useState<string | null>(null);
  const [
    showSingleStatusInfo,
    setShowSingleStatusInfo,
  ] = useState<DeviceStatusSummaryType | null>(null);

  const { doughnutChartListBox, doughnutChartContainer } = dashboardStyles({
    toSm,
    fixedContentHeight: true,
  });

  const isLoading = (isTableLoading && !isTakeMeThere) || isDeviceStatusLoading;

  useEffect(() => {
    !hovered && setHoveredElement(null);
  }, [hovered, hoveredElement, showSingleStatusInfo, deviceStatus]);

  useEffect(() => {
    // close single status info when loading
    isLoading && setShowSingleStatusInfo(null);
  }, [isLoading]);

  const generateStatusList = (deviceStatus: any) =>
    deviceStatus &&
    deviceStatus.summary.map((i: any, index: number) => {
      const isHoveredOnChart =
        index === hoveredElementToRealIndex(hoveredElement) && hovered;

      const isHoveredOnItem = i.name === hoveredListItem;

      return (
        <DoughnutChartListItem
          key={i.name}
          item={i}
          hoveredElement={hoveredElement}
          hovered={isHoveredOnChart || isHoveredOnItem}
          backgroundColor={statusColor}
          borderColor={statusBorderColor}
          setShowSingleStatusInfo={setShowSingleStatusInfo}
          isClickable
          testId={`doughnut-chart-list-item-${i.name}`}
          setHoveredListItem={setHoveredListItem}
          hoveredListItem={hoveredListItem}
        />
      );
    });

  const showSingleChartItemInfo =
    deviceStatus && showSingleStatusInfo && !isLoading;
  const showListItemsInfo = deviceStatus && !isLoading;

  const handleGoBack = () => {
    setShowSingleStatusInfo(null);
    setHoveredListItem(null);
    setHoveredElement(null);
    setHovered(false);
  };

  return (
    <CardWrapper
      // height setting on toSm screen is not needed here
      isTopSection={!toSm}
      cardTitle={t("Dashboard##device status")}
      isLoading={isLoading}
      titleInlineAction={
        showSingleStatusInfo && (
          <GoBack onGoBack={handleGoBack} text={t("Actions##back")} />
        )
      }
    >
      {isLoading && (
        <RectangleLoader
          width={"100%"}
          height={"100%"}
          testId="device-status-loader"
        />
      )}
      {showSingleChartItemInfo ? (
        <SingleStatusInfo infoData={showSingleStatusInfo} />
      ) : (
        showListItemsInfo && (
          <div css={css(doughnutChartContainer)}>
            <DoughnutChart
              deviceStatus={deviceStatus}
              setHovered={setHovered}
              setHoveredElement={setHoveredElement}
              setShowSingleStatusInfo={setShowSingleStatusInfo}
            />
            <div
              css={css([
                doughnutChartListBox,
                { justifyContent: "flex-start" },
              ])}
            >
              {generateStatusList(deviceStatus)}
            </div>
          </div>
        )
      )}
    </CardWrapper>
  );
};

export default DeviceStatus;
