import { CSSObject } from "@emotion/react";

export const useStyles = () => {
  return {
    dialogContent: {
      alignItems: "flex-start",
      padding: "0 16px 16px 0",
      display: "flex",
      flexDirection: "column",
    },
    customBadge: {
      borderRadius: "4px",
      padding: "8px",
      border: "none",
    },
    usedFiltersBlock: {
      padding: "16px 0 0 0",
      display: "flex",
      alignItems: "center",
    },
  } as CSSObject;
};
