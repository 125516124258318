/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { TFunction } from "i18next";

import { useStyles } from "../styles";

import Badge from "../../../../../../Badge/Badge";

import {
  Filter,
  inputFieldMarginBottom,
  TABLE_NAMES,
} from "../../../../../../../shared";

interface ItemsInfoBlockProps {
  resourceName: TABLE_NAMES;
  isAllTab: boolean;
  generateSelectedTabDescription: () => string | undefined;
  generateBadgeText: () => string | undefined;
  generateItemsCountToDisplay: () => string | number | undefined;
  displayFilters: boolean;
  t: TFunction<"translation", undefined>;
  filteredValues: Filter[];
}

const ItemsInfoBlock = ({
  resourceName,
  isAllTab,
  generateSelectedTabDescription,
  generateBadgeText,
  generateItemsCountToDisplay,
  displayFilters,
  t,
  filteredValues,
}: ItemsInfoBlockProps) => {
  const { dialogContent, customBadge, usedFiltersBlock } = useStyles();

  const generateFilteredValuesBadges = () =>
    filteredValues?.map((value: { id: string; name: string }) => (
      <Badge
        key={value.id}
        color="info"
        customStyle={[
          customBadge as React.CSSProperties,
          { marginRight: "4px" },
        ]}
      >
        {value.name}
      </Badge>
    ));

  return (
    <div
      css={css(dialogContent)}
      data-testid={`selected-${resourceName}-schedules-badge`}
    >
      <Typography
        variant="body2"
        sx={!isAllTab ? inputFieldMarginBottom : undefined}
      >
        {generateSelectedTabDescription()}
      </Typography>

      {!isAllTab && (
        <Badge color="info" customStyle={customBadge as React.CSSProperties}>
          {`${generateBadgeText()}: ${generateItemsCountToDisplay()}`}
        </Badge>
      )}

      {displayFilters && (
        <div css={css(usedFiltersBlock)}>
          <Typography variant="body2" sx={{ marginRight: "4px" }}>
            {t("Filter##filters used")}:
          </Typography>
          {generateFilteredValuesBadges()}
        </div>
      )}
    </div>
  );
};

export default ItemsInfoBlock;
