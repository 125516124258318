/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import Controls from "../Controls";
import CompanySelector from "../CompanySelector/CompanySelector";

import { useStyles } from "../../styles";

import Logo from "../../../Logo/Logo";
import Badge from "../../../Badge/Badge";
import MobileMenuButton from "../../../MobileMenuButton/MobileMenuButton";

import { navigateHome } from "../../../../shared/functions/functions";
import { useStyles as sharedStyles } from "../../../../shared/styles/sharedStyles";
import { RELEASE_VERSION_STATUS, useMediaQueries } from "../../../../shared";
import { FEATURE_NAMES } from "../../../../shared/featureToggles/types";
import { useAuth } from "../../../../context/Auth/AuthProvider";
import { useReleaseFeatureToggle } from "../../../../shared/featureToggles/hooks";
import FindTool from "../FindTool/FindTool";

const NavbarHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { toMd } = useMediaQueries();

  const { isReleaseFeatureEnabledForRole } = useReleaseFeatureToggle();
  const { header, headerContent, navbarItemsBlock } = useStyles();
  const { logo } = sharedStyles();

  const isAllowedToViewCompanySelector = isReleaseFeatureEnabledForRole(
    FEATURE_NAMES.ShowCompanySelector,
    user?.role
  );

  const isAllowedToFindTool = isReleaseFeatureEnabledForRole(
    FEATURE_NAMES.ShowFindTool,
    user?.role
  );

  return (
    <header data-testid="navbar-header" css={css(header)}>
      <div css={css(headerContent)}>
        <div css={css({ display: "flex" })}>
          <Logo
            css={css([logo, { padding: "0 8px 0 0" }])}
            onClick={() => navigateHome(location, navigate)}
          />

          {/* temporary badge for Beta version */}
          <Badge color="neutral" changeTypography>
            <Typography variant="body3semiBold">
              {RELEASE_VERSION_STATUS.Beta.toUpperCase()}
            </Typography>
          </Badge>
        </div>
        <div css={css(navbarItemsBlock)}>
          {isAllowedToFindTool && <FindTool />}
          {isAllowedToViewCompanySelector && <CompanySelector />}

          {toMd ? <MobileMenuButton /> : <Controls />}
        </div>
      </div>
    </header>
  );
};

export default NavbarHeader;
