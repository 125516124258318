/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ThemeContext } from "../../../../../../../../context/theme/ThemeContextProvider";
import { useMediaQueries } from "../../../../../../../../shared";
import { navigateHome } from "../../../../../../../../shared/functions/functions";
import Button from "../../../../../../../Button/Button";
import Logo from "../../../../../../../Logo/Logo";
import { useStyles } from "../../../../../../styles";

interface ICompanySelectorTopControlsProps {
  close: () => void;
  boxPadding: string;
  itemName: string;
}

const CompanySelectorTopControls = ({
  close,
  boxPadding,
  itemName,
}: ICompanySelectorTopControlsProps) => {
  const { colors } = useContext(ThemeContext);

  const { toMd, fromMd } = useMediaQueries();
  const location = useLocation();
  const navigate = useNavigate();

  const { companySelectorTopLogo, navbarItemTopControls } = useStyles();

  const handleLogoClick = () => {
    navigateHome(location, navigate);
    close();
  };

  return (
    <>
      {toMd && (
        <div css={css(companySelectorTopLogo)}>
          <Logo
            css={css({
              cursor: "pointer",
            })}
            onClick={handleLogoClick}
          />
        </div>
      )}

      <div
        css={css([
          {
            padding: boxPadding,
          },
          navbarItemTopControls,
        ])}
      >
        <Typography
          variant="h6"
          sx={{ color: colors.textDark, alignSelf: "flex-end" }}
        >
          {itemName}
        </Typography>

        {fromMd && (
          <Button
            id="controlsItemFrameBtn"
            variant="iconOnly"
            size="small"
            color="white"
            icon={<CloseIcon />}
            onClick={close}
          />
        )}
      </div>
    </>
  );
};

export default CompanySelectorTopControls;
