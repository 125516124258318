import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const QuickScheduleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
      fill="currentColor"
      sx={{ width: props.width || "16px", height: props.height || "16px" }}
    >
      <g>
        <path
          d="M13.25 15.5H11C10.7875 15.5 10.6094 15.4281 10.4656 15.2844C10.3219 15.1406 10.25 14.9625 10.25 14.75C10.25 14.5375 10.3219 14.3594 10.4656 14.2156C10.6094 14.0719 10.7875 14 11 14H13.25V6.5H2.75V8.75C2.75 8.9625 2.67813 9.14062 2.53438 9.28438C2.39063 9.42813 2.2125 9.5 2 9.5C1.7875 9.5 1.60938 9.42813 1.46563 9.28438C1.32188 9.14062 1.25 8.9625 1.25 8.75V3.5C1.25 3.0875 1.39688 2.73438 1.69063 2.44063C1.98438 2.14688 2.3375 2 2.75 2H3.5V1.25C3.5 1.0375 3.57187 0.859375 3.71562 0.715625C3.85938 0.571875 4.0375 0.5 4.25 0.5C4.4625 0.5 4.64062 0.571875 4.78438 0.715625C4.92813 0.859375 5 1.0375 5 1.25V2H11V1.25C11 1.0375 11.0719 0.859375 11.2156 0.715625C11.3594 0.571875 11.5375 0.5 11.75 0.5C11.9625 0.5 12.1406 0.571875 12.2844 0.715625C12.4281 0.859375 12.5 1.0375 12.5 1.25V2H13.25C13.6625 2 14.0156 2.14688 14.3094 2.44063C14.6031 2.73438 14.75 3.0875 14.75 3.5V14C14.75 14.4125 14.6031 14.7656 14.3094 15.0594C14.0156 15.3531 13.6625 15.5 13.25 15.5Z"
          fill="currentColor"
        />
        <path
          d="M1.6499 14.7394V11.521C1.6499 11.4039 1.68891 11.3097 1.76694 11.2381C1.84496 11.1666 1.93599 11.1309 2.04002 11.1309C2.07253 11.1309 2.10829 11.1341 2.1473 11.1406C2.18631 11.1471 2.22207 11.1634 2.25458 11.1894L4.67331 12.8084C4.73182 12.8474 4.77571 12.8945 4.80497 12.9498C4.83423 13.005 4.84886 13.0652 4.84886 13.1302C4.84886 13.1952 4.83423 13.2554 4.80497 13.3106C4.77571 13.3659 4.73182 13.413 4.67331 13.4521L2.25458 15.071C2.22207 15.097 2.18631 15.1133 2.1473 15.1198C2.10829 15.1263 2.07253 15.1296 2.04002 15.1296C1.93599 15.1296 1.84496 15.0938 1.76694 15.0223C1.68891 14.9508 1.6499 14.8565 1.6499 14.7394ZM5.55107 14.7394V11.521C5.55107 11.4039 5.59008 11.3097 5.6681 11.2381C5.74613 11.1666 5.83715 11.1309 5.94118 11.1309C5.97369 11.1309 6.00946 11.1341 6.04847 11.1406C6.08748 11.1471 6.12324 11.1634 6.15575 11.1894L8.57447 12.8084C8.63299 12.8474 8.67688 12.8945 8.70614 12.9498C8.7354 13.005 8.75002 13.0652 8.75002 13.1302C8.75002 13.1952 8.7354 13.2554 8.70614 13.3106C8.67688 13.3659 8.63299 13.413 8.57447 13.4521L6.15575 15.071C6.12324 15.097 6.08748 15.1133 6.04847 15.1198C6.00946 15.1263 5.97369 15.1296 5.94118 15.1296C5.83715 15.1296 5.74613 15.0938 5.6681 15.0223C5.59008 14.9508 5.55107 14.8565 5.55107 14.7394Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default QuickScheduleIcon;
