/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { useContext } from "react";

import { useStyles } from "./styles";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import RectangleLoader from "../../shared/components/loaders/RectangleLoader";

interface IFilterSeparatorProps {
  isInitialLoading: boolean;
  width: number | string;
  height: number | string;
  light?: boolean;
  styles?: CSSObject;
}

const FilterSeparator = ({
  isInitialLoading,
  width,
  height,
  light,
  styles,
}: IFilterSeparatorProps) => {
  const { colors } = useContext(ThemeContext);
  const { filterSeparator } = useStyles({ width, colors, light });

  return isInitialLoading ? (
    <RectangleLoader
      width={width}
      height={height}
      customStyle={{ margin: "0 16px" }}
    />
  ) : (
    <div css={css([filterSeparator, styles])} data-testid="filter-separator" />
  );
};

export default FilterSeparator;
