/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { Dispatch, SetStateAction, useContext } from "react";

import { useStyles as dashboardStyles } from "../../styles";
import { DeviceStatusSummaryType } from "../../RightSide/DeviceStatus/types";
import { creditsObjectType } from "../../LeftSide/types";

import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import { ThemeColors, useFormattedNumber } from "../../../../shared";
import Tooltip from "../../../../components/Tooltip/Tooltip";

interface DoughnutChartListItemProps {
  item: DeviceStatusSummaryType | creditsObjectType;
  hoveredElement: number | null;
  hovered: boolean;
  backgroundColor: (name: string, colors: ThemeColors) => string;
  borderColor: (name: string, colors: ThemeColors) => string;
  isClickable?: boolean;
  setShowSingleStatusInfo?: Dispatch<
    SetStateAction<DeviceStatusSummaryType | null>
  >;
  customStyle?: {};
  testId?: string;
  setHoveredListItem?: Dispatch<SetStateAction<string | null>>;
  hoveredListItem?: string | null;
}

const DoughnutChartListItem = ({
  item,
  hoveredElement,
  hovered,
  backgroundColor,
  borderColor,
  isClickable,
  customStyle,
  setShowSingleStatusInfo,
  testId,
  setHoveredListItem,
  hoveredListItem,
}: DoughnutChartListItemProps) => {
  const { colors } = useContext(ThemeContext);
  const { formattedNumber } = useFormattedNumber(item.count);

  const { t } = useTranslation();

  const {
    singleDoughnuChartItemBox,
    doughnutChartItemStick,
    doughnutChartListItemInfoBlock,
  } = dashboardStyles({ colors, item, isClickable });

  const itemColorsStyle = {
    backgroundColor: item && colors && backgroundColor(item.name, colors),
    border: item && colors && `1px solid ${borderColor(item.name, colors)}`,
  };

  const isHoveredStatus =
    hovered || (hoveredElement === null && !Boolean(hoveredListItem));

  const itemOpacityStyle = {
    opacity: isHoveredStatus ? "1" : "0.3",
    transition: "opacity 0.3s",
  };

  const numberStringIntoNumber = parseFloat(formattedNumber.replace(/,/g, ""));
  const positiveNumber = numberStringIntoNumber >= 0;
  const formattedNumberColor = positiveNumber ? colors.textDark : colors.red800;

  const handleItemClick = () =>
    setShowSingleStatusInfo?.(item as DeviceStatusSummaryType);

  const handleMouseOver = () => {
    setHoveredListItem?.(item.name);
  };

  const handleMouseLeave = () => {
    setHoveredListItem?.(null);
  };

  const renderContent = () => (
    <div
      css={css([singleDoughnuChartItemBox, itemOpacityStyle, customStyle])}
      data-testid={testId || "doughnut-chart-list-item"}
      onClick={isClickable ? handleItemClick : undefined}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {/* colored stick */}
      <div
        css={css([doughnutChartItemStick, itemColorsStyle])}
        data-testid={`colored-stick-${backgroundColor(
          item.name,
          colors
        )}-background`}
      ></div>

      {/* item info */}
      <div css={css(doughnutChartListItemInfoBlock)}>
        <Typography
          variant="body2"
          color={formattedNumberColor}
          data-testid={
            positiveNumber ? "positive-item-count" : "negative-item-count"
          }
        >
          {formattedNumber}
        </Typography>
        <Typography variant="body2" color={colors.textPlaceholder}>
          {t(`Dashboard##${item.name}`)}
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      {isClickable ? (
        <Tooltip
          data-testid={`doughnut-chart-list-item-tooltip-${item.name}`}
          title={t(`Dashboard##tooltip##press to expand`)}
          placement="top"
          size="medium"
          hasArrow
        >
          {renderContent()}
        </Tooltip>
      ) : (
        renderContent()
      )}
    </>
  );
};

export default DoughnutChartListItem;
