/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useStyles } from "./styles";

import { LogisticJournalDataProps } from "../types";

import InputField from "../../../../../InputField/InputField";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../../../../Button/Button";

import { INPUT_LENGTH, inputFieldMarginBottom } from "../../../../../../shared";

interface CreateNewHierarchyContentProps {
  verifyButtonLoading: boolean;
  logisticJournalError: null | string;
  logisticJournalData: LogisticJournalDataProps;
  getLzCodeFieldIcon: () => EmotionJSX.Element | undefined;
  handleLzCodeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleFormReset: () => void;
  handleVerify: () => void;
}

const CreateNewHierarchyDialogContent = ({
  verifyButtonLoading,
  logisticJournalError,
  logisticJournalData,
  getLzCodeFieldIcon,
  handleLzCodeInput,
  handleFormReset,
  handleVerify,
}: CreateNewHierarchyContentProps) => {
  const { t } = useTranslation();

  const { lzCodeInputWithButton, resetVerifyButton } = useStyles(
    logisticJournalError
  );

  const { lzCode, lzCompanyName } = logisticJournalData;

  const trimmedLzCode = lzCode.trim();

  const lzCodeIsValid =
    lzCode &&
    !logisticJournalError &&
    trimmedLzCode.length <= INPUT_LENGTH.LzCodeMaximum &&
    trimmedLzCode.length >= INPUT_LENGTH.LzCodeMinimum;

  return (
    <>
      <div css={css(lzCodeInputWithButton)}>
        <InputField
          id="Teltonika-code-input-field"
          testId="Teltonika-code-input-field"
          size="medium"
          fullWidth
          required
          readOnly={verifyButtonLoading || !!lzCompanyName}
          iconRight={getLzCodeFieldIcon()}
          errorText={logisticJournalError ?? ""}
          placeholder={t("Dialog##Teltonika code placeholder")}
          onChange={handleLzCodeInput}
          labelLeft={t("Dialog##Teltonika code")}
          value={lzCode}
          customStyle={inputFieldMarginBottom}
        />

        {lzCompanyName ? (
          <Button
            data-testid="reset-Teltonika-code-button"
            color={BUTTON_COLORS.Secondary}
            size={BUTTON_SIZES.Small}
            variant={BUTTON_VARIANTS.TextOnly}
            onClick={handleFormReset}
            css={css(resetVerifyButton)}
          >
            {t("Button##reset")}
          </Button>
        ) : (
          <Button
            data-testid="verify-Teltonika-code-button"
            color={BUTTON_COLORS.Secondary}
            size={BUTTON_SIZES.Small}
            variant={BUTTON_VARIANTS.TextOnly}
            onClick={handleVerify}
            disabled={!lzCodeIsValid || verifyButtonLoading || !!lzCompanyName}
            css={css(resetVerifyButton)}
          >
            {t("Button##verify")}
          </Button>
        )}
      </div>

      <InputField
        id="Teltonika-company-name-field"
        data-testid="Teltonika-company-name-field"
        size="medium"
        fullWidth
        readOnly
        required
        value={lzCompanyName}
        labelLeft={t("Dialog##Teltonika company name")}
        customStyle={inputFieldMarginBottom}
      />
    </>
  );
};

export default CreateNewHierarchyDialogContent;
