/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext } from "react";

import { SCHEDULES_NAMES } from "../../types";
import { useStyles } from "../../styles";

import IconWithTooltip from "../../../IconsWithTooltips/IconWithTooltip/IconWithTooltip";

import SingleScheduleColumnIcon from "../../../../assets/customIcons/schedules/SingleScheduleColumnIcon";
import GlobalScheduleColumnIcon from "../../../../assets/customIcons/schedules/GlobalScheduleColumnIcon";
import QuickDownloadColumnIcon from "../../../../assets/customIcons/schedules/QuickDownloadColumnIcon";
import MultipleScheduleColumnIcon from "../../../../assets/customIcons/schedules/MultipleScheduleColumnIcon";
import NoSchedulesColumnIcon from "../../../../assets/customIcons/schedules/NoSchedulesColumnIcon";
import { ACTIVITY_STATUS } from "../../../../shared";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";

export type SchedulesPaeamsTYpe = {
  id: string;
  schedules: string[];
  status: string;
};

interface SchedulesListProps {
  schedulesParams: SchedulesPaeamsTYpe;
}

const SchedulesList = ({ schedulesParams }: SchedulesListProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  const {
    scheduleColumnIcon,
    multipleRowsTooltip,
    multipleSchedulesIcon,
  } = useStyles();

  const { schedules, status } = schedulesParams;
  const isRowDisabled = status === ACTIVITY_STATUS.Deactivated;
  const disabledColor = isRowDisabled ? colors.textDarkDisabled : undefined;

  // Group schedules by type
  const processSchedules = (schedules: string[]) => {
    const result: (string | string[])[] = [];
    const otherSchedules: string[] = [];
    let quickDownloadFound = false;

    // Global schedule is always displayed first, then custom schedules, then quick schedule
    schedules?.forEach((schedule) => {
      if (schedule === SCHEDULES_NAMES.GlobalSchedule) {
        result.push([schedule]);
      } else if (schedule === SCHEDULES_NAMES.QuickDownload) {
        quickDownloadFound = true;
      } else {
        // create stack of custom schedules, since they should be displayed together
        otherSchedules.push(schedule);
      }
    });

    if (otherSchedules.length > 0) {
      result.push(otherSchedules);
    }

    if (quickDownloadFound) {
      result.push([SCHEDULES_NAMES.QuickDownload]);
    }

    return result;
  };

  const renderListOfSchedules = () => {
    let count = 1;
    return processSchedules(schedules).map((schedule) => {
      count++;
      return <div key={count}>{generateScheduleIcon(schedule)}</div>;
    });
  };

  const generateNoSchedulesView = () =>
    schedules?.length === 0 && (
      <IconWithTooltip
        iconTestId="no-schedules-icon"
        icon={<NoSchedulesColumnIcon />}
        title={t("Table##No schedule applied")}
        customIconStyle={{ height: "24px", width: "24px" }}
      />
    );

  const generateScheduleIcon = (schedule: any) => {
    if (schedule?.length === 1) {
      switch (schedule[0]) {
        case SCHEDULES_NAMES.GlobalSchedule:
          return (
            <IconWithTooltip
              iconTestId="global-schedule-icon"
              icon={
                <GlobalScheduleColumnIcon
                  currentColor={disabledColor}
                  css={css(scheduleColumnIcon)}
                />
              }
              title={t(`Table##${schedule[0]}`)}
            />
          );
        case SCHEDULES_NAMES.QuickDownload:
          return (
            <IconWithTooltip
              iconTestId="Quick-schedule-icon"
              icon={
                <QuickDownloadColumnIcon
                  currentColor={disabledColor}
                  css={css(scheduleColumnIcon)}
                />
              }
              title={t(`Table##${schedule[0]}`)}
            />
          );
        default:
          return (
            <IconWithTooltip
              iconTestId="single-schedule-icon"
              icon={
                <SingleScheduleColumnIcon
                  currentColor={disabledColor}
                  css={css(scheduleColumnIcon)}
                />
              }
              title={schedule[0]} // title is custom. It is not translatable.
            />
          );
      }
    } else {
      return (
        <IconWithTooltip
          iconTestId="multiple-schedule-icon"
          icon={
            <MultipleScheduleColumnIcon
              currentColor={disabledColor}
              css={css(multipleSchedulesIcon)}
            />
          }
          title={
            <div css={css(multipleRowsTooltip)}>
              {schedule.map((item: string) => (
                <Typography key={item} variant="body2Regular">
                  {item}
                </Typography>
              ))}
            </div>
          }
        />
      );
    }
  };

  return (
    <div css={css({ display: "flex", alignItems: "center" })}>
      {generateNoSchedulesView() || renderListOfSchedules()}
    </div>
  );
};

export default SchedulesList;
