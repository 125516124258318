/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@mui/material";
import { SetStateAction, useState } from "react";
import { useRecoilState } from "recoil";

import DesktopCompanySelector from "./components/DesktopCompanySelector/DesktopCompanySelector";
import MobileCompanySelector from "./components/MobileCompanySelector/MobileCompanySelector";
import useCompanies from "./hooks/useCompanies";

import { useStyles } from "../../styles";
import Button from "../../../Button/Button";
import { useAuth } from "../../../../context/Auth/AuthProvider";
import { MenuAnchorEl, useMediaQueries } from "../../../../shared";
import Avatar from "../../../../shared/components/avatars/Avatar";
import { refreshTableState } from "../../../../atoms/atoms";

const CompanySelector = () => {
  const { user } = useAuth();
  const { toSm, toMd } = useMediaQueries();
  const { companyList } = useCompanies(undefined, undefined, true);

  const [, setIsRefreshTable] = useRecoilState(refreshTableState);

  const [openDialog, setOpenDialog] = useState<MenuAnchorEl>(null);
  const [isMobileDialogOpen, setIsMobileDialogOpen] = useState<boolean>(false);

  const { navbarButtonTitle } = useStyles();

  const handleOpenDesktopSelector = (e: {
    currentTarget: SetStateAction<MenuAnchorEl>;
  }) => {
    setOpenDialog(e.currentTarget);
  };

  const handleCloseDesktopSelector = () => {
    setOpenDialog(null);
    setIsRefreshTable(false);
  };

  const handleOpenMobileDialog = () => {
    setIsMobileDialogOpen(true);
  };

  const handleCloseMobileDialog = () => {
    setIsMobileDialogOpen(false);
    setIsRefreshTable(false);
  };

  const handleClickFromMd = toMd
    ? handleOpenMobileDialog
    : handleOpenDesktopSelector;

  return (
    <>
      {toSm ? (
        <Button
          data-testid="company-selector-button-to-sm"
          variant="iconOnly"
          size="small"
          color="white"
          onClick={handleOpenMobileDialog}
          icon={<Avatar textToCut={user?.selectedCompany.name} />}
        />
      ) : (
        <Button
          data-testid="company-selector-button-from-sm"
          color="white"
          variant="iconLeft"
          size="small"
          icon={<Avatar textToCut={user?.selectedCompany.name} />}
          onClick={handleClickFromMd}
        >
          <Typography variant="body2" css={css(navbarButtonTitle)}>
            {user?.selectedCompany.name}
          </Typography>
        </Button>
      )}
      {toMd ? (
        <MobileCompanySelector
          isOpen={isMobileDialogOpen}
          onClose={handleCloseMobileDialog}
          companies={companyList}
        />
      ) : (
        <DesktopCompanySelector
          onClose={handleCloseDesktopSelector}
          anchorEl={openDialog}
          companies={companyList}
        />
      )}
    </>
  );
};

export default CompanySelector;
