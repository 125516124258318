import { useRecoilState } from "recoil";

import { STEP_GROUP } from "../types";
import useTutorialStepGroups from "../useTutorialStepGroups";
import { getPageGlobalStateObjectValue } from "../../Table/functions";
import {
  LOCAL_STORAGE_KEYS,
  ROUTES,
  updatePageParamsStateInLocalStorage,
  useMediaQueries,
} from "../../../shared";
import {
  currTutorialDetails,
  mobileDrawerState,
  selectedTableRows,
  settingsStatus,
  tableDetails,
  tableDetailsSectionState,
  tableHeadActivity,
} from "../../../atoms/atoms";

const useTutorialMode = () => {
  const { fromMd } = useMediaQueries();
  const {
    addNewCompanyCardStepsDesktop,
    addNewCompanyCardStepsMobile,
    addVehicleStep,
    applyScheduleStepsDesktop,
    applyScheduleStepsMobile,
    createScheduleStepsDesktop,
    createScheduleStepsMobile,
  } = useTutorialStepGroups(fromMd);

  const [tutorialDetails, setTutorialDetails] = useRecoilState(
    currTutorialDetails
  );
  const [isSettingsOpen, setIsSettingsOpen] = useRecoilState(settingsStatus);
  const [, setIsMobileDrawerOpen] = useRecoilState(mobileDrawerState);
  const [, setIsDetailsOpen] = useRecoilState(tableDetailsSectionState);
  const [, setTableDetails] = useRecoilState(tableDetails);
  const [, setSelectedRows] = useRecoilState(selectedTableRows);

  const [, setIsMobileTableHeadActive] = useRecoilState<boolean>(
    tableHeadActivity
  );

  const {
    isTutorialOpen,
    stepIndex,
    stepGroup,
    isTutorialActive,
  } = tutorialDetails;

  const callTutorialWithTimeout = (
    isOpen: boolean,
    index: number,
    group: STEP_GROUP,
    isActive: boolean,
    interval: number
  ) => {
    setTimeout(() => {
      setTutorialDetails({
        isTutorialOpen: isOpen,
        stepIndex: index,
        stepGroup: group,
        isTutorialActive: isActive,
      });
    }, interval);
  };

  const handleClearTableHead = () => {
    setSelectedRows([]);
    setIsMobileTableHeadActive(false);
  };

  const clearTableHeadIfApplySchedule = () => {
    stepGroup === STEP_GROUP.TutorialApplySchedule && handleClearTableHead();
  };

  const checkIfCorrectTutorialStep = (
    inputStepGroup: STEP_GROUP,
    index: number
  ) => isTutorialActive && inputStepGroup === stepGroup && index === stepIndex;

  const handleTutorialExit = (closeLocation?: ROUTES) => {
    setTutorialDetails({
      isTutorialOpen: false,
      stepIndex: 0,
      stepGroup: STEP_GROUP.Default,
      isTutorialActive: false,
    });

    if (isTutorialActive) {
      setIsSettingsOpen(false);
      setIsMobileDrawerOpen(false);
      closeLocation && handleCloseDetailsCompletely(closeLocation);
      clearTableHeadIfApplySchedule();
    }
  };

  const handleTutorialInCompaniesTable = (
    setIsDetailsOpen: (
      valOrUpdater: boolean | ((currVal: boolean) => boolean)
    ) => void
  ) => {
    if (checkIfCorrectTutorialStep(STEP_GROUP.TutorialNewCompanyCard, 2)) {
      setIsDetailsOpen(false);

      !isSettingsOpen &&
        setTutorialDetails({
          isTutorialOpen: true,
          stepIndex,
          stepGroup,
          isTutorialActive: true,
        });
    } else {
      handleTutorialExit(ROUTES.Companies);
    }
  };

  const handleTutorialInVehiclesTable = (numberOfVehicles: number) => {
    const tutorialSteps: { [key: string]: number } = {
      [STEP_GROUP.TutorialCreateSchedule]: fromMd ? 2 : 3,
      [STEP_GROUP.TutorialApplySchedule]: fromMd ? 1 : 2,
    };

    if (
      stepGroup === STEP_GROUP.TutorialApplySchedule &&
      numberOfVehicles === 0
    ) {
      return setTutorialDetails({
        isTutorialOpen: true,
        stepIndex: 0,
        stepGroup: STEP_GROUP.TutorialAddVehicle,
        isTutorialActive: true,
      });
    } else if (
      checkIfCorrectTutorialStep(stepGroup, tutorialSteps[stepGroup])
    ) {
      handleCloseDetailsCompletely(ROUTES.Vehicles);
      setSelectedRows([]);

      return setTutorialDetails({
        isTutorialOpen: true,
        stepIndex: tutorialSteps[stepGroup],
        stepGroup,
        isTutorialActive: true,
      });
    }

    return handleTutorialExit(ROUTES.Vehicles);
  };

  const handleTutorialStepIndexChange = (nextStepIndex: number) => {
    setTutorialDetails({
      isTutorialOpen: true,
      stepIndex: nextStepIndex,
      stepGroup: stepGroup,
      isTutorialActive: true,
    });
  };

  const handleCloseDetailsCompletely = (pageLocation: ROUTES) => {
    setTableDetails((prev: any) =>
      getPageGlobalStateObjectValue(pageLocation, prev, null)
    );
    updatePageParamsStateInLocalStorage(
      pageLocation,
      LOCAL_STORAGE_KEYS.DesktopDetailsSection,
      false
    );

    setIsDetailsOpen(false);
  };

  const currentStepGroup = (() => {
    switch (stepGroup) {
      case STEP_GROUP.TutorialNewCompanyCard:
        return fromMd
          ? addNewCompanyCardStepsDesktop
          : addNewCompanyCardStepsMobile;

      case STEP_GROUP.TutorialCreateSchedule:
        return fromMd ? createScheduleStepsDesktop : createScheduleStepsMobile;

      case STEP_GROUP.TutorialApplySchedule:
        return fromMd ? applyScheduleStepsDesktop : applyScheduleStepsMobile;

      case STEP_GROUP.TutorialAddVehicle:
        return addVehicleStep;

      default:
        return [];
    }
  })();

  const highlightElementLikeSpotlight = ({
    scheduleCards,
    applyButton,
    accordionDetails,
  }: {
    scheduleCards?: boolean;
    applyButton?: boolean;
    accordionDetails?: boolean;
  }) => {
    const aplyScheduleTutorial =
      isTutorialOpen && stepGroup === STEP_GROUP.TutorialApplySchedule;

    const scheduleCardsBlock =
      aplyScheduleTutorial &&
      scheduleCards &&
      currentStepGroup[stepIndex].target ===
        applyScheduleStepsDesktop[3].target;

    const applyButtonElement =
      aplyScheduleTutorial &&
      applyButton &&
      currentStepGroup[stepIndex].target ===
        applyScheduleStepsDesktop[4].target;

    const accordionDetailsElement =
      aplyScheduleTutorial &&
      accordionDetails &&
      currentStepGroup[stepIndex].target ===
        applyScheduleStepsDesktop[5].target;

    return scheduleCardsBlock || applyButtonElement || accordionDetailsElement;
  };

  return {
    isSettingsOpen,
    isTutorialOpen,
    stepIndex,
    stepGroup,
    isTutorialActive,
    currentStepGroup,
    callTutorialWithTimeout,
    checkIfCorrectTutorialStep,
    clearTableHeadIfApplySchedule,
    handleClearTableHead,
    handleCloseDetailsCompletely,
    handleTutorialInCompaniesTable,
    handleTutorialInVehiclesTable,
    handleTutorialStepIndexChange,
    handleTutorialExit,
    setTutorialDetails,
    setIsSettingsOpen,
    setIsMobileDrawerOpen,
    highlightElementLikeSpotlight,
  };
};

export default useTutorialMode;
