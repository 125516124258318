import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import CreateCompanyDialogContent from "./CreateCompanyDialogContent";

import { COMPANY_TYPE } from "../types";
import Dialog from "../../../../../Dialog/Dialog";
import useCompanies from "../../../../../Navbar/components/CompanySelector/hooks/useCompanies";
import useChangeSelectedCompany from "../../../../../Navbar/components/CompanySelector/hooks/useChangeSelectedCompany";

import {
  COMPANIES_ACTIONS,
  GeneralDialogProps,
} from "../../../../../../shared";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../api/resources";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { ALERT_STATUS } from "../../../../../../context/alert/types";
import useRefreshTableData from "../../../../../../shared/hooks/useRefreshTableData";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { useReleaseFeatureToggle } from "../../../../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../../../../shared/featureToggles/types";

const CreateCompanyDialog = ({ isOpen, onClose }: GeneralDialogProps) => {
  const { user, tokenInfo, setTokenInfo } = useAuth();
  const { postData } = useAxiosPrivate();
  const { handleResponse } = useResponse();
  const { refreshTableData } = useRefreshTableData();
  const {
    changeSelectedCompany,
    handleCloseDesktopDetails,
  } = useChangeSelectedCompany();
  const { isReleaseFeatureEnabledForRole } = useReleaseFeatureToggle();
  const { getQueryData } = useCompanies(true);

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isReadyToConfirm, setIsReadyToConfirm] = useState<boolean>(false);
  const [payload, setPayload] = useState<any>(null);

  const isAllowedToCreateNewHierarchy = isReleaseFeatureEnabledForRole(
    FEATURE_NAMES.AllowedToCreateNewClientCompany,
    user?.role
  );

  const initialCompanyTabValue = isAllowedToCreateNewHierarchy ? 1 : 0;

  const [companyTabValue, setCompanyTabValue] = useState(
    initialCompanyTabValue
  );

  const resetAllValues = () => {
    setIsReadyToConfirm(false);
    setPayload(null);
  };

  const handleCloseCreateCompanyDialog = () => {
    onClose(COMPANIES_ACTIONS.CreateCompany);
  };

  const handleCreateChildSubmit = async () => {
    if (isReadyToConfirm) {
      try {
        setIsLoading(true);

        const isNewClientRequest =
          isAllowedToCreateNewHierarchy && companyTabValue === 1;

        const requestData = isNewClientRequest
          ? {
              companyEndpoint: ApiResources.CompaniesParent,
              successResponse: t(`Alert##create client company success`),
            }
          : {
              companyEndpoint: ApiResources.Companies,
              successResponse: t(`Alert##create company success`),
            };

        const { companyEndpoint, successResponse } = requestData;

        const response = await postData(companyEndpoint, payload);

        if (isNewClientRequest) {
          const companyId = response.headers?.location;

          await changeSelectedCompany(
            tokenInfo,
            setTokenInfo,
            companyId,
            false
          ).then(() => {
            handleCloseDesktopDetails();
          });
        }

        await getQueryData(true);

        handleResponse(ALERT_STATUS.Success, successResponse);
      } catch (error) {
        const err = error as AxiosError<any>;
        if (err) {
          handleResponse(
            ALERT_STATUS.Critical,
            err.response?.data.message || err.message
          );
        }
      } finally {
        setIsLoading(false);
      }
      refreshTableData();
      handleCloseCreateCompanyDialog();
    }
  };

  return (
    <Dialog
      data-testid="create-company-dialog"
      title={t(`Dialog##create ${COMPANY_TYPE.Regular}`)}
      // uncomment, when final descritpion is ready:
      // description={t(
      //   `Dialog##dialog description##create ${
      //     isAllowedToCreateNewHierarchy
      //       ? COMPANY_TYPE.NewHierarchy
      //       : COMPANY_TYPE.Regular
      //   } description`,
      //   {
      //     maxNumber: COMPANY_LEVELS.MaxCompaniesLevels,
      //   }
      // )}
      extendToMaxWidth
      isTitleSeparator
      isActionsSeparator
      open={isOpen}
      close={handleCloseCreateCompanyDialog}
      submit={handleCreateChildSubmit}
      TransitionProps={{
        onExited: () => resetAllValues(),
        onEnter: () => setCompanyTabValue(initialCompanyTabValue),
      }}
      actions={
        <DialogActions
          onClose={handleCloseCreateCompanyDialog}
          isLoading={isLoading}
          isreadyToConfirm={isReadyToConfirm}
          confirmationButtonText={t("Button##create")}
          confirmationButtonTestId="add-vehicle-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-add-vehicle"
        />
      }
    >
      <CreateCompanyDialogContent
        isAllowedToCreateNewHierarchy={isAllowedToCreateNewHierarchy}
        companyTabValue={companyTabValue}
        setCompanyTabValue={setCompanyTabValue}
        setPayload={setPayload}
        setIsReady={setIsReadyToConfirm}
        resetPayloadValues={resetAllValues}
      />
    </Dialog>
  );
};

export default CreateCompanyDialog;
